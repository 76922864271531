import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { List, ListItemButton, Stack, Theme, Typography, lighten, useTheme } from '@mui/material';
import { OrderStateEnum } from '../../shared/interfaces/order-state.interface';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export interface FilterDialogEvent {
    orderStateIds: string[];
    dateRange: any;
}

export interface FilterDialogProps {
    open: boolean;
    isMobile: boolean;
    selectedDateRange: string;
    selectedOrderStateIds: string[];
    okLabel: string;
    onClose: (e: FilterDialogEvent) => void;
}

export default function FilterDialog(props: FilterDialogProps) {

    const orderStates = [
        { id: OrderStateEnum.IsPreparing, label: 'Is Preparing' },
        { id: OrderStateEnum.IsDone, label: 'Is Done' },
        { id: OrderStateEnum.IsCanceled, label: 'Is Canceled' },
        { id: OrderStateEnum.IsCreated, label: 'Is Created' },
        { id: OrderStateEnum.IsPickedUp, label: 'Is Picked Up' }
    ];

    const [selectedOrderStateIds, setSelectedOrderStateIds] = useState<string[]>(props?.selectedOrderStateIds);
    const [selectedDateRange, setSelectedDateRange] = useState<string>(props?.selectedDateRange);

    const { onClose, open } = props;

    const theme = useTheme();
    const styles = makeStyles(theme);

    function handleDateRangeClick(
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        dateRange: string) {
        setSelectedDateRange(dateRange);
    };

    const handleOkClose = () => {
        onClose({
            orderStateIds: selectedOrderStateIds,
            dateRange: selectedDateRange
        });
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                <Typography sx={styles.title} align='center'>{'Filter'}</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction={"column"}
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <List
                        sx={{ width: '100%' }}
                        component="nav"
                    >
                        {['today', 'yesterday', 'this-week'].map((range) => (
                            <ListItemButton
                                key={range}
                                selected={selectedDateRange === range}
                                onClick={(e) => handleDateRangeClick(e, range)}
                                sx={(theme) => listItemStyles(selectedDateRange === range, theme)} // Apply generic styles
                            >
                                {range.charAt(0).toUpperCase() + range.slice(1)} {/* Capitalize text */}
                            </ListItemButton>
                        ))}
                    </List>

                    <MultiSelect
                        style={{
                            width: '300px'
                        }}
                        items={orderStates}
                        placeholder='Order State'
                        idProperty='id'
                        labelProperty='label'
                        value={selectedOrderStateIds}
                        onChange={setSelectedOrderStateIds}
                    />

                </Stack>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button sx={styles.confirmButton} variant='contained' color='primary' onClick={handleOkClose}>{props?.okLabel || 'Ok'}</Button>
            </DialogActions>
        </Dialog>
    );
}

const listItemStyles = (selected: boolean, theme: any) => ({
    backgroundColor: selected ? lighten(theme.palette.secondary.main, 0.85) : 'inherit',
    '&:hover': {
        backgroundColor: lighten(theme.palette.secondary.main, 0.75),
    },
    '&.Mui-selected': {
        backgroundColor: lighten(theme.palette.secondary.main, 0.85),
        '&:hover': {
            backgroundColor: lighten(theme.palette.secondary.main, 0.65),
        },
    },
});

const makeStyles = (theme: Theme) => {
    return {
        title: {
            fontSize: '24px',
            fontWeight: 900,
            lineHeight: '30px'
        },
        actions: {
            padding: '24px 20px',
            width: '100%'
        },
        confirmButton: {
            width: '100%'
        }
    }
}