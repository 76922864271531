import { useLocation, Navigate, Outlet } from "react-router-dom";
import { RootState } from "../../store/store";
import { SecurityRole } from "../SecurityRole";
import { useSelector } from 'react-redux';


export interface AllowanceData {
    allowedRoles: SecurityRole[]
}

const RequireAuth = ({ allowedRoles }: AllowanceData) => {
    
    const auth = useSelector((state: RootState) => state.auth)

    const location = useLocation();

    const securityRoleId: string = auth?.user?.securityRoleId!;

    return (
        allowedRoles?.indexOf(securityRoleId as SecurityRole) > -1 ? <Outlet />
            : auth?.accessToken //changed from user to accessToken to persist login after refresh
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireAuth;