import React, { FC, useState } from 'react';
import {
    DragDropContext,
    Droppable,
    Draggable,
    DropResult
} from 'react-beautiful-dnd';
import { Accordion, AccordionSummary, List, ListItem, Stack, styled, Theme, Typography, useTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { newGuid, reorder } from '../../core/utilities';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import RestoreIcon from '@mui/icons-material/Restore';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { ExtraCategory } from '../../shared/interfaces/extra-category.interface';
import { ExtraMenuitem } from '../../shared/interfaces/extra-menuitem.interface';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { useTranslation } from 'react-multi-lang';
import { addExtraCategory, deleteExtraCategory, removeExtraMenuitem, updateExtraCategories, updateExtraCategory, updateExtraMenuitem, updateExtraMenuitems } from '../../core/store/features/extraCategory/extraCategorySlice';
import { setPanelContext } from '../../core/store/features/sidePanel/sidePanelSlice';
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import ContextMenu, { MenuData } from '../../components/ContextMenu/ContextMenu';
import DeleteSafeDialog from '../../dialogs/DeleteSafeDialog/DeleteSafeDialog';
import CreateMenuItemButton from '../../components/CreateMenuItemButton/CreateMenuItemButton';
import { RoundedAccordion, RoundedAccordionSummary } from '../../components/StyledComponents/StyledComponents';


export type ExtraCategoryListProps = {
    extraCategories: ExtraCategory[];
    showDeleted: boolean;
};

const ExtraCategoryList: FC<ExtraCategoryListProps> = React.memo(({
    extraCategories,
    showDeleted
}) => {

    const theme = useTheme();
    const [openSafeConfirm, setOpenSafeConfirm] = useState(false);
    const [toDeleteItem, setToDeleteItem] = useState<ExtraMenuitem | ExtraCategory | null>(null);
    const [contextMenuData, setContextMenuData] = React.useState<MenuData | null>(null);


    const axiosSecure = useAxiosSecure();
    const auth = useSelector((state: RootState) => state.auth);
    const dispatch = useDispatch();
    const t = useTranslation();

    async function onDragExtraCategoryEnd(e: DropResult) {
        // dropped outside the list
        const { destination, source, draggableId } = e;

        if (!destination) return;

        if (destination.index !== source.index) {
            const newItems = reorder(extraCategories, source.index, destination.index);

            dispatch(updateExtraCategories(
                newItems?.map((c, index) => ({
                    ...c,
                    viewOrder: index
                }))
            ));

            let response = await axiosSecure.put(`/api/extra_category/many`,
                newItems?.map((c, index) => ({
                    id: c?.id,
                    viewOrder: index
                }))
            );
        }

    };

    async function onDragExtraMenuitemEnd(e: DropResult, extraCategory: ExtraCategory) {
        // dropped outside the list
        const { destination, source, draggableId } = e;

        if (!destination) return;

        if (destination.index !== source.index) {

            let newItems: ExtraMenuitem[] = reorder(extraCategory?.extraMenuitems, source.index, destination.index);

            dispatch(updateExtraMenuitems({
                extraCategoryId: extraCategory?.id,
                extraMenuitems: newItems?.map((c, index) => ({
                    ...c,
                    viewOrder: index
                }))
            }));

            let response = await axiosSecure.put(`/api/extra_menuitem/many`,
                newItems?.map((c, index) => ({
                    id: c?.id,
                    viewOrder: index
                }))
            );

        }
    };

    function handleCreateNewMenuItemClick(event: any, extraCategory: ExtraCategory) {
        event.stopPropagation();

        dispatch(setPanelContext({
            action: 'CREATE', contextType: 'ExtraMenuitem', contextObject: {
                id: newGuid(),
                extraCategory,
                extraCategoryId: extraCategory.id
            },
            extraData: extraCategory
        }));
    }

    async function handleVisibilityExtraMenuitemClick(event: any, extraMenuitem: ExtraMenuitem) {
        event.stopPropagation();

        dispatch(updateExtraMenuitem({ ...extraMenuitem, isHidden: !extraMenuitem.isHidden }));

        console.log(extraMenuitem)
        let response = await axiosSecure.put(`/api/extra_menuitem`, {
            id: extraMenuitem?.id,
            isHidden: !extraMenuitem.isHidden
        });
    }

    function handleOpenSafeConfirm(event: any, item: ExtraMenuitem | ExtraCategory | null) {
        event.stopPropagation();
        setToDeleteItem(item);
        setOpenSafeConfirm(true);
    }



    async function handleRecoverActionClick(event: any, item: any, data: any) {
        let toRestoreItem = data;

        if ("extraCategoryId" in toRestoreItem) {

            let response = await axiosSecure.put(`/api/extra_menuitem`, {
                id: toRestoreItem?.id,
                isDeleted: false
            }, true);

            let category = { ...extraCategories.find(c => c.id === toRestoreItem.extraCategoryId) } as ExtraCategory;
            if (category) {
                // Find the menuitem and set its isDeleted property to true
                category.extraMenuitems = category.extraMenuitems.map(m =>
                    m.id === toRestoreItem.id ? { ...m, isDeleted: false } : m
                );
                
                // Dispatch the updated category
                dispatch(updateExtraCategory(category));
            }
        }
        // delete category
        else {
            let response = await axiosSecure.put(`/api/extra_category`, {
                id: toRestoreItem?.id,
                isDeleted: false
            }, true);

            // dispatch(deleteCategory(toDeleteItem?.id))
            if (response.data && response.data?.length > 0 && response?.data?.[0]) {
                dispatch(updateExtraCategory(response.data?.[0]));
            }
        }
    }

    async function handleSafeConfirmClose(event: any) {
        if (event) {
            if (toDeleteItem) {
                // delete extraMenuitem
                if ("extraCategoryId" in toDeleteItem) {
                    // TODO
                    let response = await axiosSecure.delete(`/api/extra_menuitem/${toDeleteItem?.id}`, true);
                    let category = { ...extraCategories.find(c => c.id === toDeleteItem.extraCategoryId) } as ExtraCategory;
                    if (category) {
                        // Find the menuitem and set its isDeleted property to true
                        category.extraMenuitems = category.extraMenuitems.map(m =>
                            m.id === toDeleteItem.id ? { ...m, isDeleted: true } : m
                        );

                        // Dispatch the updated category
                        dispatch(updateExtraCategory(category));
                    }
                }
                // delete extraCategory
                else {
                    let response = await axiosSecure.delete(`/api/extra_category/${toDeleteItem?.id}`, true);
                    if (response.data && response.data?.length > 0 && response?.data?.[0]) {
                        dispatch(updateExtraCategory(response.data?.[0]));
                    }
                }
            }
        }

        setToDeleteItem(null);
        setOpenSafeConfirm(false);
    }

    function handleEditExtraCategoryClick(event: any, extraCategory: ExtraCategory) {
        event.stopPropagation();
        dispatch(setPanelContext({ action: 'UPDATE', contextType: 'ExtraCategory', contextObject: extraCategory }));
    }

    function handleEditExtraMenuitemClick(event: any, extraMenuitem: ExtraMenuitem) {
        event.stopPropagation();
        dispatch(setPanelContext({ action: 'UPDATE', contextType: 'ExtraMenuitem', contextObject: extraMenuitem }));
    }

    const handleContextMenu = (event: React.MouseEvent, data: ExtraMenuitem | ExtraCategory, dataType: 'ExtraCategory' | 'ExtraMenuitem') => {
        event.preventDefault();
        setContextMenuData(
            contextMenuData === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                    data,
                    dataType
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                // Other native context menus might behave different.
                // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleContextMenuClose = () => {
        setContextMenuData(null);
    };

    function handleDeleteActionClick(event: any, item: any, data: any) {
        handleOpenSafeConfirm(event, data)
    }

    async function handleDuplicateActionClick(event: any, item: any, data: any, dataType: any) {

        if (dataType === 'ExtraMenuitem') {
            // TODO ????
            // let dataChange = { ...data, id: newGuid() };

            // let response = await axiosSecure.post(`/api/extra_category_extra_menuitem`, dataChange, true);

            // if (response?.data && response?.data?.length > 0) {
            //     dispatch(addExtraMenuitem(response?.data[0]));
            // }
        }

        if (dataType === 'ExtraCategory') {
            let dataChange = { ...data, id: newGuid() };

            let response = await axiosSecure.post(`/api/extra_category`, dataChange, true);

            if (response?.data && response?.data?.length > 0) {
                dispatch(addExtraCategory(response?.data[0]));
            }
        }

    }

    async function handleDuplicateExtraCategoryClick(event: any, extraCategory: ExtraCategory) {
        event.stopPropagation();

        let dataChange = { ...extraCategory, id: newGuid() };

        let response: any = await axiosSecure.post(`/api/extra_category`, dataChange, false);
        if (response?.data && response?.data?.length > 0) {
            dispatch(addExtraCategory(response?.data[0]));
        }
    }

    return (
        <>
            <ContextMenu
                data={contextMenuData}
                items={
                    contextMenuData?.dataType === 'ExtraCategory' ? [
                        {
                            icon: <FilterNoneIcon fontSize="small" />,
                            label: t('containers.categoryList.actions.duplicate'),
                            type: 'actionItem',
                            onAction: handleDuplicateActionClick
                        },
                        {
                            type: 'divider'
                        },
                        contextMenuData?.data?.isDeleted ? {
                            icon: <RestoreIcon fontSize="small" />,
                            label: t('Recover'),
                            type: 'actionItem',
                            onAction: handleRecoverActionClick
                        } : {
                            icon: <DeleteOutlineIcon fontSize="small" />,
                            label: t('containers.categoryList.actions.delete'),
                            type: 'actionItem',
                            onAction: handleDeleteActionClick
                        }
                    ] : [
                        contextMenuData?.data?.isDeleted ? {
                            icon: <RestoreIcon fontSize="small" />,
                            label: t('Recover'),
                            type: 'actionItem',
                            onAction: handleRecoverActionClick
                        } : {
                            icon: <DeleteOutlineIcon fontSize="small" />,
                            label: t('containers.categoryList.actions.delete'),
                            type: 'actionItem',
                            onAction: handleDeleteActionClick
                        }
                    ]
                }
                open={contextMenuData !== null}
                onClose={handleContextMenuClose}
            />

            <DeleteSafeDialog
                open={openSafeConfirm}
                onClose={(e) => handleSafeConfirmClose(e)}
                title={t('containers.categoryList.dialog.title')}
                content={t('containers.categoryList.dialog.content')}
                confirmPhrase={((toDeleteItem && "extraCategoryId" in toDeleteItem) ? toDeleteItem?.name : toDeleteItem?.name) || ''}
                noLabel={t('containers.categoryList.actions.keep')}
                yesLabel={t('containers.categoryList.actions.delete')}
            />

            {/* {(!menuitem?.menuitemExtraCategories || menuitem?.menuitemExtraCategories?.length === 0) && <Alert severity="info"
                sx={{
                    marginTop: '10px'
                }}
            >
                Vďaka extra možnostiam si vaši hostia môžu prispôsobiť položku presne podľa svojej chuti. <b>Tento krok je nepovinný. Môžete ho preskočiť a kedykoľvek sa k nemu vrátiť.</b>
            </Alert>}


            {menuitem?.menuitemExtraCategories && menuitem?.menuitemExtraCategories?.length > 0 && <Alert severity="info"
                sx={{
                    marginTop: '10px'
                }}
            >
                Medzi povinné položky s jednou voľbou patrí napríklad úroveň štipľavosti (nepikantné / jemne pikantné / pikantné). Medzi nepovinné položky patria napríklad extra ingrediencie, ktoré si hosť môže pridať (kukurica / olivy / chilli)
            </Alert>} */}

            <DragDropContext onDragEnd={(e) => onDragExtraCategoryEnd(e)}>
                <Droppable droppableId="extra-droppable-list">
                    {(provided) => (
                        <List ref={provided.innerRef} {...provided.droppableProps}
                            sx={styles.droppableList}
                        >
                            {extraCategories?.filter(extraCategory => showDeleted || !extraCategory.isDeleted)?.map((extraCategory, index) => (
                                <Draggable draggableId={extraCategory.id} index={index} key={extraCategory.id}>
                                    {(provided, snapshot) => (
                                        <RoundedAccordion
                                            disableGutters
                                            elevation={0}
                                            sx={{
                                                boxShadow: snapshot.isDragging ? "0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%);" : "initial"
                                            }}
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                        >
                                            <RoundedAccordionSummary
                                                sx={{
                                                    color: extraCategory?.isDeleted ? '#AD191E' : 'black',
                                                    textDecoration: extraCategory?.isDeleted ? 'line-through' : undefined
                                                }}
                                                expandIcon={<ExpandMoreIcon />}
                                                onContextMenu={(e: any) => handleContextMenu(e, extraCategory, 'ExtraCategory')}
                                            >
                                                <Stack
                                                    spacing={2}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    sx={styles.categoryItem}
                                                >
                                                    <div {...provided.dragHandleProps}>
                                                        <DragIndicatorIcon />
                                                    </div>

                                                    <Typography>
                                                        <strong>{extraCategory.name}</strong>
                                                    </Typography>


                                                    <Stack
                                                        spacing={2}
                                                        direction="row"
                                                        justifyContent="space-between"
                                                        alignItems="center"
                                                    >

                                                        <EditIcon
                                                            sx={styles.icon}
                                                            fontSize="small"
                                                            onClick={(e) => handleEditExtraCategoryClick(e, extraCategory)}
                                                        />

                                                        <FilterNoneIcon
                                                            sx={styles.icon}
                                                            fontSize="small"
                                                            onClick={(e) => handleDuplicateExtraCategoryClick(e, extraCategory)}
                                                        />

                                                    </Stack>
                                                </Stack>

                                            </RoundedAccordionSummary>


                                            <Stack
                                                direction="row"
                                                justifyContent="space-around"
                                                sx={{
                                                    mt: 2
                                                }}
                                            >
                                                <CreateMenuItemButton
                                                    onClick={(e) => handleCreateNewMenuItemClick(e, extraCategory)}
                                                />
                                            </Stack>

                                            <DragDropContext onDragEnd={(event) => onDragExtraMenuitemEnd(event, extraCategory)}>
                                                <Droppable droppableId={"extra-menu-items-list" + extraCategory?.id}>
                                                    {(provided2) => (
                                                        <List ref={provided2.innerRef} {...provided2.droppableProps}>
                                                            {extraCategory?.extraMenuitems?.filter(extraMenuitem => showDeleted || !extraMenuitem.isDeleted)?.map((extraMenuitem: any, index2: number) => (
                                                                <Draggable draggableId={extraMenuitem.id} index={index2} key={extraMenuitem.id}>
                                                                    {(provided2, snapshot2) => (
                                                                        <ListItem
                                                                            sx={{
                                                                                height: '80px',
                                                                                color: extraMenuitem?.isDeleted ? '#AD191E' : extraMenuitem?.isHidden ? '#8E8E93' : 'black',
                                                                                textDecoration: extraMenuitem?.isDeleted ? 'line-through' : undefined
                                                                            }}
                                                                            ref={provided2.innerRef}
                                                                            {...provided2.draggableProps}
                                                                            onContextMenu={(e: any) => handleContextMenu(e, extraMenuitem, 'ExtraMenuitem')}
                                                                        >
                                                                            <Stack
                                                                                spacing={2}
                                                                                direction="row"
                                                                                justifyContent="space-between"
                                                                                sx={{
                                                                                    ...styles.menuitem,
                                                                                    boxShadow: snapshot2.isDragging ? "0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%);" : "initial"
                                                                                }}
                                                                            >
                                                                                <Stack
                                                                                    direction="row"
                                                                                    alignItems="center"
                                                                                    spacing={2}
                                                                                    sx={styles.overflow}
                                                                                >
                                                                                    <div
                                                                                        {...provided2.dragHandleProps}
                                                                                    >
                                                                                        <DragIndicatorIcon fontSize="small" />
                                                                                    </div>
                                                                                    <Typography fontSize="small">
                                                                                        <strong>{extraMenuitem?.name}</strong>
                                                                                    </Typography>
                                                                                    <Typography fontSize="small">
                                                                                        <strong>{extraMenuitem?.weight}</strong>
                                                                                    </Typography>
                                                                                </Stack>

                                                                                <Stack direction="row" alignItems="center" spacing={2}>

                                                                                    <Typography fontSize="small">
                                                                                        <strong>{extraMenuitem?.price}€</strong>
                                                                                    </Typography>

                                                                                    <EditIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleEditExtraMenuitemClick(e, extraMenuitem)}
                                                                                    />

                                                                                    {!extraMenuitem?.isDeleted && <DeleteOutlineIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleOpenSafeConfirm(e, extraMenuitem)}
                                                                                    />}

                                                                                    {extraMenuitem?.isDeleted && <RestoreIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleRecoverActionClick(e, null, extraMenuitem)}
                                                                                    />}

                                                                                    {extraMenuitem?.isHidden ? (<VisibilityOffOutlinedIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleVisibilityExtraMenuitemClick(e, extraMenuitem)}
                                                                                    />) : (<VisibilityOutlinedIcon
                                                                                        sx={styles.icon}
                                                                                        fontSize="small"
                                                                                        onClick={(e) => handleVisibilityExtraMenuitemClick(e, extraMenuitem)}
                                                                                    />)}

                                                                                </Stack>
                                                                            </Stack>
                                                                        </ListItem>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided2.placeholder}
                                                        </List>
                                                    )}
                                                </Droppable>
                                            </DragDropContext>

                                        </RoundedAccordion>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </List>
                    )}
                </Droppable>
            </DragDropContext >
        </>
    );
});

export default ExtraCategoryList;


const styles = {
    droppableList: {
        paddingTop: '0px'
    },
    categoryItem: {
        width: '100%',
        height: '20px'
    },
    icon: {
        cursor: 'pointer'
    },
    menuitem: {
        width: '100%',
        height: '50px',
        borderRadius: '0.6rem',
        bgcolor: 'white'
    },
    overflow: {
        overflow: 'hidden'
    }
}