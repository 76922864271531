import { Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/core/store/store';
import api, { BASE_URL } from '../../app/core/api/axios';
import { useDispatch } from 'react-redux';
import { updateStripeOnboarded } from '../../app/core/store/features/auth/authSlice';
import { set } from 'lodash';
import { useLocation } from 'react-router-dom';
import { newGuid } from '../../app/core/utilities';
import useAxiosSecure from '../../hooks/useAxiosSecure';
import { displayNotification } from '../../app/core/store/features/notification/notificationSlice';

const CLIENT_ID = 'bramble'
const CLIENT_SECRET = 'rvW1fpinL2yyF3N82pJu'
const REFRESH_TOKEN = 'd4d8e1dae4ec67b45121215b0be8cbf4'
const CLOUD_ID = '376925962'
const BRANCH_ID = '171432607'


const DotyposConnect = () => {

    const location = useLocation();
    const [refreshToken, setRefreshToken] = useState<string | null>('');
    const [stateParam, setStateParam] = useState<string | null>('');
    const [dotyposConnected, setDotyposConnected] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [state, setState] = useState("rand");
    // const [stripeConnectedError, setStripeConnectedError] = React.useState('');

    // const responseUrl = `${BASE_URL}/api/receive-token`;
    const responseUrl = window.location.href;

    const axiosSecure = useAxiosSecure();

    const [dotyposConnectLink, setDotyposConnectLink] = useState(`https://admin.dotykacka.cz/client/connect?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}&scope=*&redirect_uri=${responseUrl}&state=${state}`);

    const user = useSelector((state: RootState) => state.auth.user);
    const dispatch = useDispatch();


    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tokenParam = queryParams.get('token');
        const cloudIdParam = queryParams.get('cloudid');
        const _stateParam = queryParams.get('state');

        // console.log(tokenParam)
        // console.log(_stateParam, state)

        if (tokenParam) {
            setRefreshToken(tokenParam);
            saveRefreshToken(tokenParam, cloudIdParam)
        }

        setStateParam(_stateParam);
    }, [location.search, setRefreshToken]);


    async function saveRefreshToken(refreshToken: string, cloudId: string | null) {

        let response = await axiosSecure.put(`/api/restaurant`,
            {
                id: user?.restaurantId,
                isDotyposOnboarded: true,
                dotyposRefreshToken: refreshToken,
                dotyposCloudId: cloudId
            }, false);

        console.log(response)
    }

    const getDotyposConnected = async () => {
        user?.restaurant?.isDotyposOnboarded ? setDotyposConnected(true) : setDotyposConnected(false);
        // if (!user?.isDotyposOnboarded) {
        //     setIsLoading(true);
        //     // const response = await api.get(`api/user/stripe/onboarding/${user?.id}`);
        //     // const data = await response.data;
        //     setIsLoading(false);
        //     // if (data.stripeOnboardingLink) {
        //     //     setDotyposConnected(false);
        //     //     // setStripeConnectLink(data.stripeOnboardingLink);
        //     // } else {
        //     //     setDotyposConnected(true);
        //     //     // const userUpdated = { ...user };
        //     //     // if (userUpdated) {
        //     //     //     userUpdated.stripeOnboarded = true;
        //     //     // }
        //     //     // dispatch(updateStripeOnboarded(true));
        //     // }
        // }
        setIsLoading(false);
    }

    useEffect(() => {
        getDotyposConnected();
    }, []);

    async function handleSyncClicked(event: any) {
        // setIsLoading(true);
        let response = await axiosSecure.post(`/api/restaurant/sync_from_dotypos`, {
            id: user?.restaurantId
        }, true);
        console.log(response);

        if (response.status === 200) {
            dispatch(displayNotification({
                message: "Menu was imported successfully",
                autoHideDuration: 5000,
                severity: "success"
            }));
        } else {
            dispatch(displayNotification({
                message: "Failed to import Menu",
                autoHideDuration: 5000,
                severity: "error"
            }));
        }
        // setIsLoading(false);
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                textAlign: 'center',
            }}
        >
            {!dotyposConnected ? <div>
                <Typography>Connect your Dotykačka account to get paid!</Typography>
                <br />
                <br />

                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        // navigate to stripe connect link
                        window.location.href = dotyposConnectLink;
                    }}
                    disabled={isLoading}
                >
                    {
                        isLoading
                            ? "Loading..."
                            : "Connect with Dotykačka"}

                </Button>

            </div>
                : <div>
                    <Typography>Your Dotykačka account is now connected!</Typography>
                    <br />
                    <br />

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSyncClicked}
                    >
                        {"Import menu from Dotykačka"}
                    </Button>
                </div>
            }
        </div>
    )
}

export default DotyposConnect;