
/*

    Stolen from Bitwarden:

    https://github.com/bitwarden/clients/blob/8dc11a6f120cf91b0a2b129514f52528b9deaa43/libs/common/src/tools/generator/password/password-generation.service.ts#L40-L144

*/

export interface PasswordGeneratorOptions {
    length: number;
    ambiguous: boolean;
    number: boolean;
    minNumber: number;
    uppercase: boolean;
    minUppercase: number;
    lowercase: boolean;
    minLowercase: number;
    special: boolean;
    minSpecial: number;
    type: string;
    numWords: number;
    wordSeparator: string;
    capitalize: boolean;
    includeNumber: boolean;
}

const DefaultOptions: PasswordGeneratorOptions = {
    length: 14,
    ambiguous: false,
    number: true,
    minNumber: 1,
    uppercase: true,
    minUppercase: 0,
    lowercase: true,
    minLowercase: 0,
    special: false,
    minSpecial: 1,
    type: "password",
    numWords: 3,
    wordSeparator: "-",
    capitalize: false,
    includeNumber: false
};


export class PasswordGenerationService {

    async generatePassword(options: PasswordGeneratorOptions): Promise<string> {
        // overload defaults with given options
        const o = Object.assign({}, DefaultOptions, options);

        // sanitize
        this.sanitizePasswordLength(o, true);

        const minLength: number = o.minUppercase + o.minLowercase + o.minNumber + o.minSpecial;
        if (o.length < minLength) {
            o.length = minLength;
        }

        const positions: string[] = [];
        if (o.lowercase && o.minLowercase > 0) {
            for (let i = 0; i < o.minLowercase; i++) {
                positions.push("l");
            }
        }
        if (o.uppercase && o.minUppercase > 0) {
            for (let i = 0; i < o.minUppercase; i++) {
                positions.push("u");
            }
        }
        if (o.number && o.minNumber > 0) {
            for (let i = 0; i < o.minNumber; i++) {
                positions.push("n");
            }
        }
        if (o.special && o.minSpecial > 0) {
            for (let i = 0; i < o.minSpecial; i++) {
                positions.push("s");
            }
        }
        while (positions.length < o.length) {
            positions.push("a");
        }

        // shuffle
        await this.shuffleArray(positions);

        // build out the char sets
        let allCharSet = "";

        let lowercaseCharSet = "abcdefghijkmnopqrstuvwxyz";
        if (o.ambiguous) {
            lowercaseCharSet += "l";
        }
        if (o.lowercase) {
            allCharSet += lowercaseCharSet;
        }

        let uppercaseCharSet = "ABCDEFGHJKLMNPQRSTUVWXYZ";
        if (o.ambiguous) {
            uppercaseCharSet += "IO";
        }
        if (o.uppercase) {
            allCharSet += uppercaseCharSet;
        }

        let numberCharSet = "23456789";
        if (o.ambiguous) {
            numberCharSet += "01";
        }
        if (o.number) {
            allCharSet += numberCharSet;
        }

        const specialCharSet = "!@#$%^&*";
        if (o.special) {
            allCharSet += specialCharSet;
        }

        let password = "";
        let positionChars!: string;
        for (let i = 0; i < o.length; i++) {
            switch (positions[i]) {
                case "l":
                    positionChars = lowercaseCharSet;
                    break;
                case "u":
                    positionChars = uppercaseCharSet;
                    break;
                case "n":
                    positionChars = numberCharSet;
                    break;
                case "s":
                    positionChars = specialCharSet;
                    break;
                case "a":
                    positionChars = allCharSet;
                    break;
                default:
                    break;
            }

            const randomCharIndex = await this.randomNumber(0, positionChars.length - 1);
            password += positionChars.charAt(randomCharIndex);
        }

        return password;
    }

    private capitalize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    private async appendRandomNumberToRandomWord(wordList: string[]) {
        if (wordList == null || wordList.length <= 0) {
            return;
        }
        const index = await this.randomNumber(0, wordList.length - 1);
        const num = await this.randomNumber(0, 9);
        wordList[index] = wordList[index] + num;
    }

    // ref: https://stackoverflow.com/a/12646864/1090359
    private async shuffleArray(array: string[]) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = await this.randomNumber(0, i);
            [array[i], array[j]] = [array[j], array[i]];
        }
    }

    private sanitizePasswordLength(options: any, forGeneration: boolean) {
        let minUppercaseCalc = 0;
        let minLowercaseCalc = 0;
        let minNumberCalc: number = options.minNumber;
        let minSpecialCalc: number = options.minSpecial;

        if (options.uppercase && options.minUppercase <= 0) {
            minUppercaseCalc = 1;
        } else if (!options.uppercase) {
            minUppercaseCalc = 0;
        }

        if (options.lowercase && options.minLowercase <= 0) {
            minLowercaseCalc = 1;
        } else if (!options.lowercase) {
            minLowercaseCalc = 0;
        }

        if (options.number && options.minNumber <= 0) {
            minNumberCalc = 1;
        } else if (!options.number) {
            minNumberCalc = 0;
        }

        if (options.special && options.minSpecial <= 0) {
            minSpecialCalc = 1;
        } else if (!options.special) {
            minSpecialCalc = 0;
        }

        // This should never happen but is a final safety net
        if (!options.length || options.length < 1) {
            options.length = 10;
        }

        const minLength: number = minUppercaseCalc + minLowercaseCalc + minNumberCalc + minSpecialCalc;
        // Normalize and Generation both require this modification
        if (options.length < minLength) {
            options.length = minLength;
        }

        // Apply other changes if the options object passed in is for generation
        if (forGeneration) {
            options.minUppercase = minUppercaseCalc;
            options.minLowercase = minLowercaseCalc;
            options.minNumber = minNumberCalc;
            options.minSpecial = minSpecialCalc;
        }
    }

    async randomNumber(min: number, max: number): Promise<number> {
        return new Promise((resolve, reject) => {
            // Create byte array and fill with 1 random number
            var byteArray = new Uint8Array(1);
            window.crypto.getRandomValues(byteArray);

            var range = max - min + 1;
            var max_range = 256;
            if (byteArray[0] >= Math.floor(max_range / range) * range)
                resolve(this.randomNumber(min, max));
            else
                resolve(min + (byteArray[0] % range));
        });
    }
}