import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface ConfirmDialogProps {
    open: boolean;
    noLabel: string;
    yesLabel: string;
    title: string;
    content: string;
    onClose: (value: boolean) => void;
}

export default function ConfirmDialog(props: ConfirmDialogProps) {

    const { onClose, open } = props;

    const handleYesClose = () => {
        onClose(true);
    };

    const handleNoClose = () => {
        onClose(false);
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            // onClose={handleNoClose}
        >
            <DialogTitle>{props?.title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {props?.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="black" size="small" onClick={handleNoClose}>{props?.noLabel || 'No'}</Button>
                <Button variant='contained' size="small" onClick={handleYesClose}>{props?.yesLabel || 'Yes'}</Button>
            </DialogActions>
        </Dialog>
    );
}