import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface NotificationState {
    message: string;
    severity: string;
    autoHideDuration?: number;
    messageId?: number;
}

const initialState: NotificationState = {
    message: "",
    severity: "",
    autoHideDuration: 0,
    messageId: 0
}

export const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        displayNotification: (state, action: PayloadAction<NotificationState>) => {
            state.messageId = Math.floor(Math.random() * (20000 - 0 + 1)) + 0;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
            state.autoHideDuration = action.payload.autoHideDuration;
        }
    },
})

// Action creators are generated for each case reducer function
export const { displayNotification } = notificationSlice.actions

export default { notificationReducer: notificationSlice.reducer } 
