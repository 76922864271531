import { useEffect, useState } from "react";
import { IconButton, ListItem, ListItemButton, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { useTranslation } from 'react-multi-lang';
import { User } from '../../shared/interfaces/user.interfaces';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import UserDialog from "../../dialogs/UserDialog/UserDialog";
import { newGuid } from "../../core/utilities";
import { SecurityRole } from "../../core/security/SecurityRole";


export interface StaffEditorProps {
    action: 'CREATE' | 'UPDATE' | 'VIEW' | null,
    data: any;
    onChangeData: (data: any) => void;
}

export default function StaffEditor(props: StaffEditorProps) {

    const theme = useTheme();

    const [restaurantUsers, setRestaurantUsers] = useState<User[]>([]);
    const [openAddUser, setOpenAddUser] = useState(false);
    const [editUser, setEditUser] = useState<any>(null);

    const axiosSecure = useAxiosSecure();
    const auth = useSelector((state: RootState) => state.auth);
    const t = useTranslation();


    async function apiCall() {
        let filter = "";

        if (auth.user) {
            filter += `restaurantId=${auth.user?.restaurantId}&`;
        }

        let response = await axiosSecure.get(`/api/user?${filter}`, false);
        setRestaurantUsers(response.data.map((u: User) => ({
            ...u,
            _created: false,
            _updated: false,
            _deleted: false
        })).filter((u: User) => u.securityRoleId !== SecurityRole.Owner));
    }

    useEffect(() => {
        apiCall();
    }, [props?.data])

    useEffect(() => {
        props?.onChangeData({
            isValid: true,
            users: restaurantUsers
        })
    }, [restaurantUsers])


    function handleUserClick(e: any) {
        setEditUser(e);
        setOpenAddUser(true);
    }

    function handleDeleteClick(event: any, user: User) {
        event.stopPropagation();

        let newRestaurantUsers = restaurantUsers.map(u => {
            if (u.id === user.id) {
                return ({ ...u, _deleted: true });
            } else {
                return u;
            }
        });

        setRestaurantUsers([...newRestaurantUsers]);
    }

    function handleUnDeleteClick(event: any, user: User) {
        event.stopPropagation();

        let newRestaurantUsers = restaurantUsers.map(u => {
            if (u.id === user.id) {
                return ({ ...u, _deleted: false });
            } else {
                return u;
            }
        });

        setRestaurantUsers([...newRestaurantUsers]);
    }

    function handleAddUserClose(value: any) {
        setOpenAddUser(false);

        if (value != null) {
            if (restaurantUsers.find(u => u.id === value?.id)) {
                // update
                let newRestaurantUsers = restaurantUsers.map(u => {
                    if (u.id === value.id) {
                        return ({ ...u, ...value, _updated: true });
                    } else {
                        return u;
                    }
                });
                setRestaurantUsers([...newRestaurantUsers]);
            } else {
                // add
                setRestaurantUsers([...restaurantUsers, ({
                    ...value,
                    id: newGuid(),
                    restaurantId: auth.user?.restaurantId,
                    // TODO
                    password1: "1111",
                    password2: "1111",
                    _updated: false,
                    _created: true
                })]);
            }
        }

        setEditUser(null);
    };

    return (
        <>
            <UserDialog
                open={openAddUser}
                onClose={handleAddUserClose}
                data={editUser}
                noLabel={t('containers.editors.festival.dialog.actions.cancel')}
                yesLabel={t('containers.editors.festival.dialog.actions.save')}
            />

            <IconButton
                color="black"
                size='small'
                onClick={() => { handleUserClick(null) }}
            >
                <AddIcon />
            </IconButton>

            {restaurantUsers?.map((user, index) => (
                <ListItem
                    key={index}
                    sx={{
                        backgroundColor: user?._created ? theme.palette.success.main : (user?._updated ? theme.palette.warning.main : null)
                    }}
                    onClick={(e) => { handleUserClick(user) }}
                >
                    <ListItemButton>
                        <Stack
                            sx={styles.listItem}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <Typography sx={{
                                textDecoration: user?._deleted ? 'line-through' : 'unset'
                            }}><strong>{user?.firstName} {user?.lastName}</strong></Typography>

                            {!user?._deleted && <Tooltip title="Delete">
                                <DeleteIcon sx={styles.icon} onClick={(e) => handleDeleteClick(e, user)} />
                            </Tooltip>}

                            {user?._deleted && <Tooltip title="Restore">
                                <RestoreFromTrashIcon sx={styles.icon} onClick={(e) => handleUnDeleteClick(e, user)} />
                            </Tooltip>}
                        </Stack>
                    </ListItemButton>
                </ListItem>)
            )}
        </>
    );
}


const styles = {
    listItem: {
        width: '100%'
    },
    icon: {
        cursor: 'pointer'
    }
}