import { ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme } from "@mui/material";
import * as React from "react";
import { HTMLAttributeAnchorTarget } from "react";
import { Link, useLocation } from "react-router-dom";


export interface SideMenuItemProps {
    text: string,
    link: string,
    target?: HTMLAttributeAnchorTarget | undefined,
    open: boolean,
    onClick?: () => void;
    children: any
}

export default function SideMenuItem(props: SideMenuItemProps) {

    const theme = useTheme();
    const location = useLocation();

    return (
        <>
            <ListItem
                component={Link}
                target={props.target}
                to={props.link}
                key={props.text}
                onClick={props.onClick}
                sx={{
                    // padding: '4px 10px'
                }}
            >
                <ListItemButton
                    selected={location?.pathname?.startsWith(props?.link)}
                    sx={{
                        height: 56,
                        width: 64,
                        // padding: '0px 14px',
                        borderRadius: '4px',
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: props.open ? 2 : 'auto'
                        }}
                    >
                        {React.cloneElement(props.children, {
                            sx: {
                                fontSize: "1.6rem"
                            }
                        })}
                    </ListItemIcon>
                    {props.open && <ListItemText primary={props.text}
                        primaryTypographyProps={{
                            fontWeight: '600',
                            fontSize: "1.0rem"
                        }}
                    />}
                </ListItemButton>
            </ListItem>
        </>
    );
}