import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Stack, Theme, Typography, colors, useTheme } from '@mui/material';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useTranslation } from 'react-multi-lang';

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export interface HelpDialogProps {
    open: boolean;
    isMobile: boolean;
    okLabel: string;
    onClose: () => void;
}

export default function HelpDialog(props: HelpDialogProps) {

    const { onClose, open } = props;
    
    const t = useTranslation();
    const theme = useTheme();
    const styles = makeStyles(theme);


    const handleOkClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
        >
            <DialogTitle>
                <Typography sx={styles.title} align='center'>{t('dialogs.titles.help')}</Typography>
            </DialogTitle>
            <DialogContent>
                <Stack
                    direction={props?.isMobile ? "column" : "row"}
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                >
                    <Stack
                        sx={styles.card}
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        spacing={1}
                    >
                        <LocalPhoneOutlinedIcon />
                        <div>
                            <Typography sx={styles.labelBold}>+421 123 456 789</Typography>
                            <Typography>{t('dialogs.contents.callUs')}</Typography>
                        </div>
                        <Button
                            sx={styles.actionButton}
                            variant='contained'
                            color='black'
                            size='small'
                            onClick={() => window.location.href = 'tel:+421123456789'}
                        >
                            {t('dialogs.actions.call')}
                        </Button>
                    </Stack>

                    <Stack
                        sx={styles.card}
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="space-between"
                    >
                        <EmailOutlinedIcon />
                        <div>
                            <Typography sx={styles.labelBold}>info@bramble.sk</Typography>
                            <Typography>{t('dialogs.contents.contactUs')}</Typography>
                        </div>
                        <Button
                            sx={styles.actionButton}
                            variant='contained'
                            color='black'
                            size='small'
                            onClick={() => window.location.href = 'mailto:info@bramble.sk'}
                        >
                            {t('dialogs.actions.contact')}
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions sx={styles.actions}>
                <Button sx={styles.confirmButton} variant='contained' color='greyLight' onClick={handleOkClose}>{props?.okLabel || 'Ok'}</Button>
            </DialogActions>
        </Dialog>
    );
}

const makeStyles = (theme: Theme) => {
    return {
        title: {
            fontSize: '24px',
            fontWeight: 900,
            lineHeight: '30px'
        },
        card: {
            width: '248px',
            minHeight: '160px',
            padding: '16px 16px 8px 16px',
            borderRadius: '4px',
            color: theme.palette.black.main,
            background: theme.palette.greyLight.main
        },
        labelBold: {
            fontSize: {
                xs: '14px',
                sm: '14px',
                md: '18px',
                lg: '18px',
                xl: '18px',
            },
            fontWeight: 700,
            lineHeight: '27px'
        },
        actions: {
            width: '100%'
        },
        actionButton: {
            width: '100%'
        },
        confirmButton: {
            width: '100%'
        }
    }
}