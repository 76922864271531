import useAxiosSecure from '../../hooks/useAxiosSecure';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { RootState } from '../../app/core/store/store';
import { useTheme } from '@mui/material';
import { ExtraCategory } from '../../app/shared/interfaces/extra-category.interface';
import { addExtraCategories } from '../../app/core/store/features/extraCategory/extraCategorySlice';
import ExtraCategoryList from '../../app/containers/ExtraCategoryList/ExtraCategoryList';


export default function Modifications() {

    const auth = useSelector((state: RootState) => state.auth);
    const showDeleted = useSelector((state: RootState) => state.ui.showDeleted);

    const extraCategories = useSelector((state: RootState) => [...state.extraCategory.extraCategories])
        .filter(c => c.restaurantId === auth.user?.restaurantId)
        .sort((a: ExtraCategory, b: ExtraCategory) => a.viewOrder - b.viewOrder);

    const axiosSecure = useAxiosSecure();
    const dispatch = useDispatch();
    const theme = useTheme();


    useEffect(() => {
        async function apiCall() {

            let filter = "";

            if (auth.user) {
                filter += `restaurantId=${auth.user?.restaurantId}&`;
            }

            let response = await axiosSecure.get(`/api/extra_category?${filter}`, true);
            dispatch(addExtraCategories(response.data));
        }

        apiCall();
    }, [])


    return (
        <>
            <ExtraCategoryList
                extraCategories={extraCategories}
                showDeleted={showDeleted}
            />
        </>
    );
}