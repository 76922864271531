import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { List, ListItem } from "@mui/material";
import FileUploading from 'react-files-uploading';
import { uniqBy } from 'lodash';



export interface MultiFileUploadProps {
    label: string;
    placeholderFiles?: any[];
    maxNumber: number;
    onChangeData: (data: any) => void;
}

export default function MultiFileUpload(props: MultiFileUploadProps) {


    const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
    const [placeholderFiles, setPlaceholderFiles] = useState<any[]>(props?.placeholderFiles || []);


    useEffect(() => {

        props?.onChangeData({
            files: filesToUpload ? filesToUpload : null
        });

    }, [filesToUpload])

    function handleFileChange(fileList: File[]) {
        let unique = uniqBy(fileList, 'name');
        if (props?.maxNumber != null) {
            unique = unique.slice(0, props?.maxNumber);
        }
        setFilesToUpload([...unique]);
        // free memory when ever this component is unmounted
        // return () => URL.revokeObjectURL(objectUrl)
    };


    return (
        <FileUploading value={filesToUpload} onChange={handleFileChange}
            acceptType={['jpg', 'jpeg', 'png', 'mp4']} maxFileSize={2097152} multiple>
            {({
                fileList,
                errors,
                isDragging,
                onFileUpload,
                onFileRemoveAll,
                onFileUpdate,
                onFileRemove,
                dragProps,
            }) => {
                return (
                    <>
                        <Box
                            id="btn-upload"
                            onClick={onFileUpload}
                            {...dragProps}
                            sx={styles.fileUploadContainer}
                        >
                            {errors && errors.maxNumber && (
                                <span>Number of selected files exceed maxNumber</span>
                            )}

                            <>
                                <Typography noWrap
                                    sx={styles.fileUploadLabel}
                                >
                                    {props?.label || "Choose file"}
                                </Typography>

                                {filesToUpload && filesToUpload?.length > 0 ? (
                                    <List sx={{
                                        overflow: 'auto'
                                    }}>
                                        {filesToUpload?.map((fileToUpload, index) => (<ListItem
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={styles.fileName}
                                            >
                                                {fileToUpload?.name}

                                            </Typography>

                                            {/* <DeleteIcon
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onFileRemove(index);
                                                }} /> */}
                                        </ListItem>))}
                                    </List>) : (
                                    <List sx={{
                                        overflow: 'auto'
                                    }}>
                                        {placeholderFiles?.map((fileToUpload, index) => (<ListItem
                                            key={index}
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center"
                                            }}
                                        >
                                            <Typography
                                                sx={styles.fileName}
                                            >
                                                {fileToUpload?.name}

                                            </Typography>
                                        </ListItem>))}
                                    </List>)}
                            </>

                        </Box>
                    </>
                );
            }}
        </FileUploading>
    )
}


const styles = {
    fileUploadContainer: {
        background: 'white',
        position: 'relative',
        overflowX: 'hidden',
        overflowY: 'auto',
        color: '#AEAEB2', // theme.palette.primary.main,
        borderRadius: '8px',
        border: `3px dashed #AEAEB2`, // ${theme.palette.primary.main}`,
        height: '200px',
        width: '100%',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'filter ease-in-out 200ms',
        ':hover': {
            filter: 'brightness(0.85)'
        }
    },
    fileUploadLabelContainer: {
        position: 'absolute',
        zIndex: 1,
        color: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    fileUploadLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fileName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}