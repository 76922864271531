import { Box, Button, Stack, useTheme } from "@mui/material";
import { useLocation } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../core/store/store";
import { useState } from "react";
import MenuItemEditor from "../MenuItemEditor/MenuItemEditor";
import CategoryEditor from "../CategoryEditor/CategoryEditor";
import useAxiosSecure from "../../../hooks/useAxiosSecure";
import ExtraMenuItemEditor from "../ExtraMenuItemEditor/ExtraMenuItemEditor";
import { newGuid } from "../../core/utilities";
import { MenuItemExtraCategory } from "../../shared/interfaces/menuitem-extra-category.interface";
import { useTranslation } from "react-multi-lang";
import { setPanelOpen } from "../../core/store/features/sidePanel/sidePanelSlice";
import { showLoading } from "../../core/store/features/loading/loadingSlice";
import { addCategory, addMenuitem, deleteCategory, updateCategory, updateMenuitem, upsertMenuitem } from "../../core/store/features/category/categorySlice";
import { addExtraCategory, addExtraMenuitem, deleteExtraCategory, deleteExtraMenuitem, updateExtraCategory, updateExtraMenuitem } from "../../core/store/features/extraCategory/extraCategorySlice";
import { displayNotification } from "../../core/store/features/notification/notificationSlice";
import RestaurantEditor from "../RestaurantEditor/RestaurantEditor";
import { addRestaurant, deleteRestaurant, updateRestaurant } from "../../core/store/features/restaurant/restaurantSlice";
import { Festival } from "../../shared/interfaces/festival.interface";
import { Restaurant } from "../../shared/interfaces/restaurant.interface";
import ConfirmDialog from "../../dialogs/ConfirmDialog/ConfirmDialog";
import DeleteSafeDialog from "../../dialogs/DeleteSafeDialog/DeleteSafeDialog";
import { BoldTypography } from "../../components/StyledComponents/StyledComponents";
import { updateUser, updateUserRestaurant } from "../../core/store/features/auth/authSlice";
import ProfileEditor from "../ProfileEditor/ProfileEditor";
import StaffEditor from "../StaffEditor/StaffEditor";
import { User } from "../../shared/interfaces/user.interfaces";
import DotyposConnect from "../../../pages/DotyposConnect/DotyposConnect";


export interface SidePanelProps { }

export default function SidePanel(props: SidePanelProps) {

    const theme = useTheme();
    const location = useLocation();
    const dispatch = useDispatch();
    const axiosSecure = useAxiosSecure();
    const sidePanel = useSelector((state: RootState) => state.sidePanel);
    const auth = useSelector((state: RootState) => state.auth);
    const t = useTranslation();

    const [isValid, setIsValid] = useState<any>(false);
    const [dataChange, setDataChange] = useState<any>(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
    const [openSafeConfirm, setOpenSafeConfirm] = useState(false);
    const [deleteId, setDeleteId] = useState<string | null>(null);
    const [toDeleteItem, setToDeleteItem] = useState<Festival | Restaurant | null>(null);


    function handleCloseSidePanel() {
        dispatch(setPanelOpen(false));
    }

    function handleSaveData() {
        setOpenConfirm(true);
    }

    function handleDelete(id: string) {
        setDeleteId(id);
        setOpenDeleteConfirm(true);
    };

    function handleSafeDelete(event: any) {
        setToDeleteItem(event);
        setDeleteId(event.id);
        setOpenSafeConfirm(true);
    };

    async function saveData() {

        switch (sidePanel.context?.contextType) {
            case "Staff": {

                // TODO
                await dataChange?.users?.map(async (user: User) => {

                    if (user?._created) {
                        let response = await axiosSecure.post(`/api/user/register`, user, false);
                        // if (response?.data && response?.data?.length > 0) {
                        //     dispatch(updateUser(response?.data[0]));
                        // }
                    }

                    if (user?._updated) {
                        let response = await axiosSecure.put(`/api/user`, user, false);
                        // if (response?.data && response?.data?.length > 0) {
                        //     dispatch(updateUser(response?.data[0]));
                        // }
                    }

                    if (user?._deleted && !user?._created) {
                        let response = await axiosSecure.delete(`/api/user/${user?.id}`, false);
                        // if (response?.data && response?.data?.length > 0) {
                        //     dispatch(updateUser(response?.data[0]));
                        // }
                    }
                })

                break;
            }
            case "Profile": {

                if (sidePanel?.context?.action === 'UPDATE') {
                    let response = await axiosSecure.put(`/api/auth/me`, dataChange, true);
                    if (response?.data && response?.data?.length > 0) {
                        dispatch(updateUser(response?.data[0]));
                    }
                }

                break;
            }
            case "Category": {

                if (sidePanel?.context?.action === 'UPDATE') {
                    let response = await axiosSecure.put(`/api/category`, dataChange, true);
                    if (response?.data && response?.data?.length > 0) {
                        dispatch(updateCategory(response?.data[0]));
                    }
                }

                if (sidePanel?.context?.action === 'CREATE') {
                    let response = await axiosSecure.post(`/api/category`, {
                        ...dataChange,
                        restaurantId: auth.user?.restaurantId
                    }, true);

                    if (response?.data && response?.data?.length > 0) {
                        dispatch(addCategory(response?.data[0]));
                    }
                }

                break;
            }
            case "ExtraCategory": {
                if (sidePanel?.context?.action === 'UPDATE') {
                    let response = await axiosSecure.put(`/api/extra_category`, dataChange, true);
                    if (response?.data && response?.data?.length > 0) {
                        dispatch(updateExtraCategory(response?.data[0]));
                    }
                }

                if (sidePanel?.context?.action === 'CREATE') {
                    let response = await axiosSecure.post(`/api/extra_category`, {
                        ...dataChange,
                        restaurantId: auth.user?.restaurantId
                    }, true);

                    if (response?.data && response?.data?.length > 0) {
                        dispatch(addExtraCategory(response?.data[0]));
                    }
                }
                break;
            }
            case "Menuitem": {

                // TODO
                dispatch(showLoading({
                    showLoading: true
                }));

                if (sidePanel?.context?.action === 'UPDATE') {
                    let response = await axiosSecure.put(`/api/menuitem`, { ...dataChange, image: null }, false);
                    if (response?.data && response?.data?.length > 0) {
                        dispatch(updateMenuitem(response?.data[0]));
                    }
                }

                if (sidePanel?.context?.action === 'CREATE') {
                    let response = await axiosSecure.post(`/api/menuitem`, { ...dataChange, image: null }, false);
                    if (response?.data && response?.data?.length > 0) {
                        dispatch(addMenuitem(response?.data[0]));
                    }
                }

                let currentExtraCategories = sidePanel?.context?.contextObject?.menuitemExtraCategories;

                if (currentExtraCategories) {
                    let addedExtraCategoryIds = dataChange?._extraCategoryIds?.filter((ecId: string) =>
                        currentExtraCategories?.map((e: MenuItemExtraCategory) => e.extraCategoryId)?.indexOf(ecId) === -1)

                    let removedExtraCategories = currentExtraCategories?.filter((ec: MenuItemExtraCategory) =>
                        dataChange?._extraCategoryIds?.indexOf(ec.extraCategoryId) === -1);

                    let removedExtraCategoriesIds = removedExtraCategories?.map((r: MenuItemExtraCategory) => r.id)

                    if (addedExtraCategoryIds && addedExtraCategoryIds?.length > 0) {
                        let assign_response = await axiosSecure.put(`/api/menuitem_extra_category/many`,
                            addedExtraCategoryIds.map((extraCategoryId: string, index: number) => ({
                                id: newGuid(),
                                menuitemId: sidePanel?.context?.contextObject?.id,
                                extraCategoryId: extraCategoryId,
                                viewOrder: index
                            })), false);
                    }

                    if (removedExtraCategoriesIds && removedExtraCategoriesIds?.length > 0) {
                        for (let id of removedExtraCategoriesIds) {
                            let unssign_response = await axiosSecure.delete(`/api/menuitem_extra_category/${id}`, false);
                        }
                    }
                } else {
                    // non existing menuitem
                    let addedExtraCategoryIds = dataChange?._extraCategoryIds;

                    if (addedExtraCategoryIds && addedExtraCategoryIds?.length > 0) {
                        let assign_response = await axiosSecure.put(`/api/menuitem_extra_category/many`,
                            addedExtraCategoryIds.map((extraCategoryId: string, index: number) => ({
                                id: newGuid(),
                                menuitemId: sidePanel?.context?.contextObject?.id,
                                extraCategoryId: extraCategoryId,
                                viewOrder: index
                            })), false);
                    }
                }

                // upload image
                if (dataChange?.image && auth?.user?.restaurantId) {
                    let upload_res = await axiosSecure.uploadImage(`/api/thumbnail/upload_image`,
                        'menuitems', dataChange?.id, dataChange?.image, false
                    ).catch(e => {
                        dispatch(displayNotification({
                            message: t('messages.uploadError'),
                            severity: "error"
                        }));
                    })
                }

                // reload data
                let menuitem_res = await axiosSecure.get(`/api/menuitem?id=${sidePanel?.context?.contextObject?.id}`, false);
                if (menuitem_res?.data && menuitem_res?.data?.length > 0) {
                    dispatch(upsertMenuitem(menuitem_res?.data[0]));
                }

                dispatch(showLoading({
                    showLoading: false
                }));

                break;
            }
            case "ExtraMenuitem": {

                if (sidePanel?.context?.action === 'UPDATE') {
                    // update item
                    let response = await axiosSecure.put(`/api/extra_menuitem`, dataChange, true);
                    if (response?.data && response?.data?.length > 0) {
                        dispatch(updateExtraMenuitem(response?.data[0]));
                    }
                }

                if (sidePanel?.context?.action === 'CREATE') {
                    let response = await axiosSecure.post(`/api/extra_menuitem`, dataChange, true);
                    if (response?.data && response?.data?.length > 0) {
                        dispatch(addExtraMenuitem(response?.data[0]));
                    }
                }
                break;
            }
            case "Restaurant": {

                dispatch(showLoading({
                    showLoading: true
                }));

                if (sidePanel?.context?.action === 'UPDATE') {
                    let body = {
                        ...dataChange.restaurant
                    }

                    let response = await axiosSecure.put(`/api/restaurant`, body, false);
                    if (response?.data && response?.data?.length > 0) {
                        if (sidePanel?.context?.extraData?.isFromManage === true) {
                            dispatch(updateUserRestaurant({ restaurant: response?.data[0] as any }));
                        } else {
                            dispatch(updateRestaurant(response?.data[0]));
                        }
                    }
                }

                if (sidePanel?.context?.action === 'CREATE') {

                    let body = {
                        ...dataChange,
                        user: {
                            ...dataChange.user,
                            password1: 'admin',
                            password2: 'admin'
                        }
                    }

                    let response = await axiosSecure.post(`/api/restaurant/register`, {
                        ...body
                    }, false);

                    if (response?.data && response?.data?.length > 0) {
                        dispatch(addRestaurant(response?.data[0]));
                    }
                }

                // upload image
                if (dataChange?.restaurant?.image) {
                    let upload_res = await axiosSecure.uploadImage(`/api/thumbnail/upload_image`,
                        'restaurants', dataChange?.restaurant?.id, dataChange?.restaurant?.image, false
                    ).catch(e => {
                        dispatch(displayNotification({
                            message: t('messages.uploadError'),
                            severity: "error"
                        }));
                    })
                }

                // upload logo
                if (dataChange?.restaurant?.logo) {
                    let upload_res = await axiosSecure.uploadImage(`/api/thumbnail/upload_image`,
                        'logo', dataChange?.restaurant?.id, dataChange?.restaurant?.logo, false
                    ).catch(e => {
                        dispatch(displayNotification({
                            message: t('messages.uploadError'),
                            severity: "error"
                        }));
                    })
                }

                // upload home carousel
                if (dataChange?.restaurant?.clearHomeKioskImages || (dataChange?.restaurant?.homeKioskImages && dataChange?.restaurant?.homeKioskImages?.length > 0)) {

                    /*
                        url: string, bucketName: string, ownerName: string, context: string, files: File[], overwrite?: boolean | undefined, displayLoading?: boolean
                    */

                    let upload_res = await axiosSecure.uploadFiles(`/api/kiosk/upload_files`,
                        'carousel', dataChange?.restaurant?.id, 'home', dataChange?.restaurant?.homeKioskImages, true, false
                    ).catch(e => {
                        dispatch(displayNotification({
                            message: t('messages.uploadError'),
                            severity: "error"
                        }));
                    })
                }

                // upload menu carousel
                if (dataChange?.restaurant?.clearMenuKioskImages || dataChange?.restaurant?.menuKioskImages && dataChange?.restaurant?.menuKioskImages?.length > 0) {

                    /*
                        url: string, bucketName: string, ownerName: string, context: string, files: File[], overwrite?: boolean | undefined, displayLoading?: boolean
                    */

                    let upload_res = await axiosSecure.uploadFiles(`/api/kiosk/upload_files`,
                        'carousel', dataChange?.restaurant?.id, 'menu', dataChange?.restaurant?.menuKioskImages, true, false
                    ).catch(e => {
                        dispatch(displayNotification({
                            message: t('messages.uploadError'),
                            severity: "error"
                        }));
                    })
                }


                dispatch(showLoading({
                    showLoading: false
                }));

                break;
            }
        }
    }

    async function deleteData() {
        if (deleteId) {
            switch (sidePanel.context?.contextType) {
                case "Category": {
                    let response = await axiosSecure.delete(`/api/category/${deleteId}`, true);
                    if (response.data && response.data?.length > 0 && response?.data?.[0]) {
                        dispatch(updateCategory(response.data?.[0]));
                    }
                    break;
                }
                case "ExtraCategory": {
                    let response = await axiosSecure.delete(`/api/extra_category/${deleteId}`, true);
                    if (response.data && response.data?.length > 0 && response?.data?.[0]) {
                        dispatch(updateExtraCategory(response.data?.[0]));
                    }
                    break;
                }
                case "Menuitem": {
                    break;
                }
                case "ExtraMenuitem": {
                    let response = await axiosSecure.delete(`/api/extra_menuitem/${deleteId}`, true);
                    if (response.data && response.data?.length > 0 && response?.data?.[0]) {
                        dispatch(updateExtraMenuitem(response.data?.[0]));
                    }
                    break;
                }
                case "Restaurant": {
                    await axiosSecure.delete(`/api/restaurant/${deleteId}`, true);
                    dispatch(deleteRestaurant(deleteId))
                    break;
                }
            }
        }
    }

    async function handleConfirmClose(value: boolean) {
        setOpenConfirm(false);
        if (value) {
            await saveData();
            dispatch(setPanelOpen(false));
        }
    };

    async function handleDeleteConfirmClose(value: boolean) {
        setOpenDeleteConfirm(false);
        if (value) {
            await deleteData();
            dispatch(setPanelOpen(false));
        }
    };


    async function handleSafeConfirmClose(event: any) {
        if (event) {
            if (toDeleteItem) {
                await deleteData();
                dispatch(setPanelOpen(false));
            }
        }

        setToDeleteItem(null);
        setDeleteId(null);
        setOpenSafeConfirm(false);
    }

    function handleChangeData(event: any) {
        setIsValid(event?.isValid);
        setDataChange(event);
    }

    function getDeleteLabel() {
        let objectName: string;
        switch (sidePanel.context?.contextType) {
            case "Category":
                objectName = t('dialogs.contents.category');
                break;
            case "ExtraCategory":
                objectName = t('dialogs.contents.extraCategory');
                break;
            case "Menuitem":
                objectName = t('dialogs.contents.menuitem');
                break;
            case "ExtraMenuitem":
                objectName = t('dialogs.contents.extraMenuitem');
                break;
            case "Restaurant":
                objectName = t('dialogs.contents.restaurant');
                break;
            default:
                objectName = "object";
                break;
        }

        return `${t('dialogs.contents.deleteObject')} ${objectName}?`;
    }

    return (
        <>
            <ConfirmDialog
                open={openConfirm}
                onClose={handleConfirmClose}
                title={t('dialogs.titles.save')}
                content={t('dialogs.contents.save')}
                noLabel={t('dialogs.actions.cancel')}
                yesLabel={t('dialogs.actions.save')}
            />

            <ConfirmDialog
                open={openDeleteConfirm}
                onClose={handleDeleteConfirmClose}
                title={t('dialogs.titles.delete')}
                content={getDeleteLabel()}
                noLabel={t('dialogs.actions.cancel')}
                yesLabel={t('dialogs.actions.delete')}
            />

            <DeleteSafeDialog
                open={openSafeConfirm}
                onClose={(e) => handleSafeConfirmClose(e)}
                title={t('dialogs.titles.delete')}
                content={getDeleteLabel()}
                confirmPhrase={toDeleteItem?.name || ''}
                noLabel={t('dialogs.actions.keep')}
                yesLabel={t('dialogs.actions.delete')}
            />

            <Box>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    sx={styles.headerContainer}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                    >
                        <CloseIcon
                            fontSize="small"
                            sx={styles.closeIcon}
                            onClick={handleCloseSidePanel}
                        />

                        {sidePanel.context?.contextType === "Category" &&
                            <>
                                <BoldTypography>
                                    {sidePanel?.context?.action === 'UPDATE' ? t('containers.sidePanel.titles.update.category') : t('containers.sidePanel.titles.create.category')}
                                </BoldTypography>
                            </>
                        }

                        {sidePanel.context?.contextType === "Menuitem" &&
                            <>
                                <BoldTypography>
                                    {sidePanel?.context?.action === 'UPDATE' ? t('containers.sidePanel.titles.update.menuitem') : t('containers.sidePanel.titles.create.menuitem')}
                                </BoldTypography>
                            </>
                        }

                        {sidePanel.context?.contextType === "ExtraCategory" &&
                            <>
                                <BoldTypography>
                                    {sidePanel.context.action === 'UPDATE' ? t('containers.sidePanel.titles.update.extraCategory') : t('containers.sidePanel.titles.create.extraCategory')}
                                </BoldTypography>
                            </>
                        }

                        {sidePanel.context?.contextType === "ExtraMenuitem" &&
                            <>
                                <BoldTypography>
                                    {sidePanel.context.action === 'UPDATE' ? t('containers.sidePanel.titles.update.extraMenuitem') : t('containers.sidePanel.titles.create.extraMenuitem')}
                                </BoldTypography>
                            </>
                        }

                        {sidePanel.context?.contextType === "Festival" &&
                            <>
                                <BoldTypography>
                                    {sidePanel.context.action === 'UPDATE' ? t('containers.sidePanel.titles.update.festival') : t('containers.sidePanel.titles.create.festival')}
                                </BoldTypography>
                            </>
                        }

                        {sidePanel.context?.contextType === "Restaurant" &&
                            <>
                                <BoldTypography>
                                    {sidePanel.context.action === 'UPDATE' ? t('containers.sidePanel.titles.update.restaurant') : t('containers.sidePanel.titles.create.restaurant')}
                                </BoldTypography>
                            </>
                        }

                        {/* TODO */}
                        {sidePanel.context?.contextType === "Profile" &&
                            <>
                                <BoldTypography>
                                    {'Profile'}
                                </BoldTypography>
                            </>
                        }

                        {/* TODO */}
                        {sidePanel.context?.contextType === "Staff" &&
                            <>
                                <BoldTypography>
                                    {'Staff'}
                                </BoldTypography>
                            </>
                        }

                        {/* TODO */}
                        {sidePanel.context?.contextType === "Dotypos" &&
                            <>
                                <BoldTypography>
                                    {'Dotypos'}
                                </BoldTypography>
                            </>
                        }

                        {/* TODO */}
                        {sidePanel.context?.contextType === "Artist" &&
                            <>
                                <BoldTypography>
                                    {'Artist'}
                                </BoldTypography>
                            </>
                        }
                    </Stack>

                    {sidePanel.context?.action !== 'VIEW' && <Button
                        disabled={!!!isValid}
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={handleSaveData}
                    >
                        {t('containers.sidePanel.actions.save')}
                    </Button>}
                </Stack>

                <Box
                    sx={styles.contentContainer}
                >
                    {sidePanel.context?.contextType === "Dotypos" &&
                        <>
                            <DotyposConnect />
                        </>
                    }

                    {sidePanel.context?.contextType === "Profile" &&
                        <>
                            <ProfileEditor
                                action={sidePanel.context.action || "CREATE"}
                                data={sidePanel.context.contextObject}
                                onChangeData={handleChangeData}
                            />
                        </>
                    }

                    {sidePanel.context?.contextType === "Staff" &&
                        <>
                            <StaffEditor
                                data={sidePanel.context?.contextObject}
                                action={sidePanel.context?.action}
                                onChangeData={handleChangeData}
                            />
                        </>
                    }

                    {sidePanel.context?.contextType === "Category" &&
                        <>
                            <CategoryEditor
                                isExtraCategory={false}
                                data={sidePanel.context?.contextObject}
                                action={sidePanel.context?.action}
                                onChangeData={handleChangeData}
                                onDelete={handleDelete}
                            />
                        </>
                    }

                    {sidePanel.context?.contextType === "Menuitem" &&
                        <>
                            <MenuItemEditor
                                data={sidePanel.context.contextObject}
                                onChangeData={handleChangeData}
                            />
                        </>
                    }

                    {sidePanel.context?.contextType === "ExtraCategory" &&
                        <>
                            <CategoryEditor
                                isExtraCategory={true}
                                data={sidePanel.context?.contextObject}
                                action={sidePanel.context?.action}
                                onChangeData={handleChangeData}
                                onDelete={handleDelete}
                            />
                        </>
                    }

                    {sidePanel.context?.contextType === "ExtraMenuitem" &&
                        <>
                            <ExtraMenuItemEditor
                                action={sidePanel.context.action || "CREATE"}
                                data={sidePanel.context.contextObject}
                                extraCategory={sidePanel.context.extraData}
                                onChangeData={handleChangeData}
                                onDelete={handleDelete}
                            />
                        </>
                    }

                    {sidePanel.context?.contextType === "Restaurant" &&
                        <>
                            <RestaurantEditor
                                showIsPopular={sidePanel?.context?.extraData?.showIsPopular == null ? true : sidePanel?.context?.extraData?.showIsPopular}
                                showDelete={sidePanel?.context?.extraData?.showDelete == null ? true : sidePanel?.context?.extraData?.showDelete}
                                action={sidePanel.context.action || "CREATE"}
                                data={sidePanel.context.contextObject}
                                onChangeData={handleChangeData}
                                onDelete={handleSafeDelete}
                            />
                        </>
                    }
                </Box>

            </Box >
        </>
    );
}


const styles = {
    headerContainer: {
        height: '83.5px',
        margin: '0px 10px',
        background: 'white',
        position: 'sticky',
        top: 0,
        zIndex: 2
    },
    contentContainer: {
        p: 2
    },
    closeIcon: {
        cursor: 'pointer',
        ml: 2
    }
}