import { RootState } from '../app/core/store/store';
import { useSelector } from 'react-redux';

export interface Notification {
    message: string;
    type: string;
}

const useNotification = () => {
    const notification = useSelector((state: RootState) => state?.notification);
    return notification;
}

export default useNotification;