import { createSlice, createEntityAdapter, EntityState, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Order } from '../../../../shared/interfaces/order.interface';
import { OrderStateEnum } from '../../../../shared/interfaces/order-state.interface';

// Create entity adapter
const orderAdapter = createEntityAdapter<Order>({
    // Define the id for the entities
    selectId: (order) => order.id,
    sortComparer: (a, b) => {
        const timestampA = new Date(a.createdAt).getTime();
        const timestampB = new Date(b.createdAt).getTime();
        return timestampB - timestampA;
    }
});

// Initial state using the entity adapter
export interface OrderState extends EntityState<Order> { }

const initialState: OrderState = orderAdapter.getInitialState();

const orderSlice = createSlice({
    name: 'order',
    initialState,
    reducers: {
        reset: () => initialState,

        // Add multiple orders
        addOrders: orderAdapter.addMany,

        // Add a single order
        addOrder: orderAdapter.addOne,

        // Upsert a single order
        upsertOrder: orderAdapter.upsertOne,

        // Upsert multiple orders
        upsertOrders: orderAdapter.upsertMany,

        // Update a single order
        updateOrder: orderAdapter.updateOne,

        // Update multiple orders
        updateOrders: orderAdapter.updateMany,

        // Delete a single order
        deleteOrder: orderAdapter.removeOne,

        // Delete multiple orders
        deleteOrders: orderAdapter.removeMany,
    },
});

// Export the reducer and actions
export const {
    reset,
    addOrders,
    addOrder,
    upsertOrder,
    upsertOrders,
    updateOrder,
    updateOrders,
    deleteOrder,
    deleteOrders,
} = orderSlice.actions;

// Export the reducer
export default orderSlice.reducer;


export const selectAllOrders = (state: RootState) => state.order.entities;
export const selectOrderById = (state: RootState, orderId: string) => state.order.entities[orderId];
export const selectOrderIds = (state: RootState) => state.order.ids;

// Selector to get all orders ordered by createdAt (newest to oldest)
export const selectAllOrdersOrderedByCreatedAt = (state: RootState) =>
    createSelector([selectAllOrders], (orders) =>
        orders ? Object.values(orders).sort((a, b) => new Date((b as Order).createdAt).getTime() - new Date((a as Order).createdAt).getTime()) : []
    )(state);


// export const selectOrdersForBoardByRestaurantId = createSelector(
//     [selectAllOrders, (state, restaurantId) => restaurantId],
//     (orders, restaurantId) => orders ? Object.values(orders)
//     .filter((i: any) => i?.restaurantId === restaurantId) : []
// )


export const selectOrdersForBoardByRestaurantId = createSelector(
    [selectAllOrders, (state, restaurantId) => restaurantId],
    (orders, restaurantId) => orders ? Object.values(orders) : []
)


export const selectOrdersForCurrentRestaurant = (state: RootState) =>
    createSelector(
        [selectAllOrders, (state) => state.auth.user?.restaurantId],
        (orders, restaurantId) =>
            orders ? Object.values(orders).filter((order) => order?.restaurantId === restaurantId
                // && [OrderStateEnum.IsCreated, OrderStateEnum.IsPreparing, OrderStateEnum.IsDone].indexOf((order as Order).orderStateId as any) > -1
            ).sort((a, b) => new Date((b as Order).createdAt).getTime() - new Date((a as Order).createdAt).getTime()) : []
    )(state);