import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { Autocomplete, Box, Grid, IconButton, Stack, TextField, useTheme } from "@mui/material";
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { useTranslation } from 'react-multi-lang';
import { StyledTextField } from '../../components/StyledComponents/StyledComponents';
import { User } from '../../shared/interfaces/user.interfaces';
import KeyIcon from '@mui/icons-material/Key';
import SetPasswordDialog from '../../dialogs/SetPasswordDialog/SetPasswordDialog';
import countries from '../../../assets/countries.json';


export interface ProfileEditorProps {
    action: 'CREATE' | 'UPDATE' | 'VIEW' | null,
    data: User;
    onChangeData: (data: any) => void;
}

export default function ProfileEditor(props: ProfileEditorProps) {

    const theme = useTheme();

    const [login, setLogin] = useState(props?.data?.login || "");
    const [email, setEmail] = useState(props?.data?.email || "");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState(props?.data?.phone || "");
    const [street, setStreet] = useState(props?.data?.street || "");
    const [zipCode, setZipCode] = useState(props?.data?.zipCode || "");
    const [city, setCity] = useState(props?.data?.city || "");
    const [state, setState] = useState<any>(countries[0] || null);

    const [loginError, setLoginError] = useState("");
    const [emailError, setEmailError] = useState("");
    const [firstNameError, setFirstNameError] = useState("");
    const [lastNameError, setLastNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [streetError, setStreetError] = useState("");
    const [zipCodeError, setZipCodeError] = useState("");
    const [cityError, setCityError] = useState("");
    const [stateError, setStateError] = useState("");

    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const [isPasswordDialogOpen, setIsPasswordDialogOpen] = useState(false);

    const axiosSecure = useAxiosSecure();
    const auth = useSelector((state: RootState) => state.auth);
    const t = useTranslation();


    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    useEffect(() => {
        setLogin(props?.data?.login || "");
        setEmail(props?.data?.email || "");
        setPhone(props?.data?.phone || "");
        setFirstName(props?.data?.firstName || "");
        setLastName(props?.data?.lastName || "");
        setPhone(props?.data?.phone || "");
        setStreet(props?.data?.street || "");
        setZipCode(props?.data?.zipCode || "");
        setCity(props?.data?.city || "");

        let country = countries.find(c => c.code === props?.data?.state)
        setState(country || null);

    }, [props?.data])


    useEffect(() => {

        props?.onChangeData({
            isValid: validateForm(),
            id: props?.data?.id,
            login,
            email,
            firstName,
            lastName,
            phone,
            street,
            zipCode,
            city,
            state: state?.code
        });

    }, [login, email, firstName, lastName, phone, street, zipCode, city, state])

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!login) {
            setLoginError("* Required");
            isValid = false;
        } else {
            setLoginError("");
        }

        if (!email || !isValidEmail(email)) {
            setEmailError("Invalid email address");
            isValid = false;
        } else {
            setEmailError("");
        }

        if (!firstName) {
            setFirstNameError("* Required");
            isValid = false;
        } else {
            setFirstNameError("");
        }

        if (!lastName) {
            setLastNameError("* Required");
            isValid = false;
        } else {
            setLastNameError("");
        }

        if (!phone) {
            setPhoneError("* Required");
            isValid = false;
        } else {
            setPhoneError("");
        }

        if (!street) {
            setStreetError("* Required");
            isValid = false;
        } else {
            setStreetError("");
        }

        if (!zipCode) {
            setZipCodeError("* Required");
            isValid = false;
        } else {
            setZipCodeError("");
        }

        if (!city) {
            setCityError("* Required");
            isValid = false;
        } else {
            setCityError("");
        }

        if (!state) {
            setStateError("* Required");
            isValid = false;
        } else {
            setStateError("");
        }

        return isValid;
    };

    const isValidEmail = (email: string) => {
        // Implement your email validation logic here
        return /\S+@\S+\.\S+/.test(email);
    };

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    function handleLoginChange(event: any) {
        setLogin(event.target.value);
    }

    function handleEmailChange(event: any) {
        setEmail(event.target.value);
    }

    function handlePhoneChange(event: any) {
        setPhone(event.target.value);
    }

    function handleStreetChange(event: any) {
        setStreet(event.target.value);
    }

    function handleZipCodeChange(event: any) {
        setZipCode(event.target.value);
    }

    function handleCityChange(event: any) {
        setCity(event.target.value);
    }

    function handleStateChange(event: any, newValue: string | null) {
        setState(newValue);
    }

    function handleFirstNameChange(event: any) {
        setFirstName(event.target.value);
    }

    function handleLastNameChange(event: any) {
        setLastName(event.target.value);
    }

    async function handleSetNewPasword(event: any) {
        setIsPasswordDialogOpen(false);

        if (event) {
            let response = await axiosSecure.put(`/api/auth/set_password`, {
                id: props?.data?.id,
                password: event["password"]
            }, true);
        }
    }

    return (
        <>
            {isPasswordDialogOpen && <SetPasswordDialog
                open={isPasswordDialogOpen}
                isMobile={isMobile}
                title={'Reset Password'}
                okLabel='Reset'
                cancelLabel='Cancel'
                onClose={handleSetNewPasword}
            />}

            <Stack spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography><strong>{t('containers.editors.profile.labels.userName')}</strong></Typography>

                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            <StyledTextField
                                variant="outlined"
                                color="secondary"
                                value={login}
                                onChange={handleLoginChange}
                                error={!!loginError}
                                helperText={loginError}
                            />
                            <IconButton
                                onClick={() => setIsPasswordDialogOpen(true)}
                            >
                                <KeyIcon />
                            </IconButton>
                        </Stack>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography><strong>{t('containers.editors.profile.labels.firstName')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            color="secondary"
                            value={firstName}
                            onChange={handleFirstNameChange}
                            error={!!firstNameError}
                            helperText={firstNameError}
                        />
                    </Grid>

                    <Grid item xs={6}>
                        <Typography><strong>{t('containers.editors.profile.labels.lastName')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            color="secondary"
                            value={lastName}
                            onChange={handleLastNameChange}
                            error={!!lastNameError}
                            helperText={lastNameError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('containers.editors.profile.labels.email')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            color="secondary"
                            value={email}
                            onChange={handleEmailChange}
                            error={!!emailError}
                            helperText={emailError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('containers.editors.profile.labels.phoneNumber')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            color="secondary"
                            value={phone}
                            onChange={handlePhoneChange}
                            error={!!phoneError}
                            helperText={phoneError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('containers.editors.profile.labels.street')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            color="secondary"
                            value={street}
                            onChange={handleStreetChange}
                            error={!!streetError}
                            helperText={streetError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('containers.editors.profile.labels.zipCode')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            color="secondary"
                            value={zipCode}
                            onChange={handleZipCodeChange}
                            error={!!zipCodeError}
                            helperText={zipCodeError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('containers.editors.profile.labels.city')}</strong></Typography>
                        <StyledTextField
                            variant="outlined"
                            color="secondary"
                            value={city}
                            onChange={handleCityChange}
                            error={!!cityError}
                            helperText={cityError}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography><strong>{t('containers.editors.profile.labels.country')}</strong></Typography>
                        <Autocomplete
                            autoHighlight
                            getOptionLabel={(option) => option.label}
                            options={countries}
                            value={state}
                            onChange={handleStateChange}
                            renderOption={(props, option) => (
                                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                    <img
                                        loading="lazy"
                                        width="20"
                                        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                        alt=""
                                    />
                                    {option.label} ({option.code})
                                </Box>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    error={!!stateError}
                                    color="secondary"
                                    helperText={stateError}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        {/* <StyledTextField
                            required
                            variant="outlined"
                            value={state}
                            onChange={handleStateChange}
                            error={!!stateError}
                            helperText={stateError}
                        /> */}
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
}


const styles = {

}