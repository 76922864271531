import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export type ContextType =
    'Category' |
    'Menuitem' |
    'ExtraCategory' |
    'ExtraMenuitem' |
    'Order' |
    'Restaurant' |
    'Festival' |
    'Dotypos' |
    'Profile' |
    'Artist' |
    'Staff';

export type Action = 'CREATE' | 'UPDATE' | 'VIEW';


export interface SidePanelState {
    isOpen: boolean;
    context?: SidePanelContextState | null;
}

export interface SidePanelContextState {
    action: Action | null;
    contextType: ContextType | null;
    contextObject: any;
    extraData?: any | null;
}

const initialState: SidePanelState = {
    isOpen: false,
    context: null
}

export const sidePanelSlice = createSlice({
    name: 'sidePanel',
    initialState,
    reducers: {
        reset: () => initialState,
        setPanelOpen: (state, action: PayloadAction<boolean>) => {
            state.isOpen = action.payload;
        },
        setPanelContext: (state, action: PayloadAction<SidePanelContextState>) => {
            state.isOpen = true;
            state.context = {
                action: action.payload.action,
                contextType: action.payload.contextType,
                contextObject: action.payload.contextObject,
                extraData: action.payload.extraData
            };
        }
    },
})

// Action creators are generated for each case reducer function
export const { reset, setPanelOpen, setPanelContext } = sidePanelSlice.actions

export default { sidePanelReducer: sidePanelSlice.reducer } 
