
export interface CountryType {
    code: string;
    label: string;
    phone: string;
    suggested?: boolean;
}

// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
export const countries: readonly CountryType[] = [
    // { code: 'CN', label: 'China', phone: '86' },
    { code: 'cz', label: 'Čeština', phone: '420' },
    {
        code: 'de',
        label: 'Deutsch',
        phone: '49',
        suggested: true,
    },
    // { code: 'ES', label: 'Spain', phone: '34' },
    // {
    //     code: 'FR',
    //     label: 'France',
    //     phone: '33',
    //     suggested: true,
    // },
    { code: 'hu', label: 'Magyar', phone: '36' },
    // { code: 'IN', label: 'India', phone: '91' },
    // { code: 'IT', label: 'Italy', phone: '39' },
    // {
    //     code: 'JP',
    //     label: 'Japan',
    //     phone: '81',
    //     suggested: true,
    // },
    { code: 'pl', label: 'Polska', phone: '48' },
    // { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'sk', label: 'Slovenčina', phone: '421' },
    // { code: 'UA', label: 'Ukraine', phone: '380' },
    {
        code: 'us',
        label: 'English',
        phone: '1',
        suggested: true,
    }
];