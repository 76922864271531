import { v4 as uuidv4 } from 'uuid';

// import * as dayJs from "dayjs";
import dayJs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import customParseFormat from "dayjs/plugin/customParseFormat";

dayJs.extend(utc);
dayJs.extend(timezone);
dayJs.extend(customParseFormat);

// const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
// dayJs.tz.setDefault("UTC"); 

export const dayjs = dayJs;

export function newGuid() {
    return uuidv4();
}

export const reorder = <T>(
    list: T[],
    startIndex: number,
    endIndex: number
): T[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

export function isBoolean(obj: any) {
    return obj === true || obj === false || toString.call(obj) === '[object Boolean]';
};

export function isNumber(o: any) {
    const s = String(o)
    return !isNaN(+s) && isFinite(+s) && (typeof o === 'number' || !/e/i.test(s))
}

export function omit(obj: any, omitKey: any) {
    return Object.keys(obj).filter(key => key != omitKey).reduce((result, key) => ({ ...result, [key]: obj[key] }), {});
}

export function getDayName(dateStr: string, locale: string) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: 'long' });
}

export function capitalize(s: string) {
    return s && s[0].toUpperCase() + s.slice(1);
}

export function getLocale() {
    if (navigator.languages != undefined)
        return navigator.languages[0];
    return navigator.language;
}