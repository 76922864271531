import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'


export interface LoadingState {
    showLoading: boolean;
}

const initialState: LoadingState = {
   showLoading: false
}

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        showLoading: (state, action: PayloadAction<LoadingState>) => {
            state.showLoading = action.payload.showLoading;
        }
    },
})

// Action creators are generated for each case reducer function
export const { showLoading } = loadingSlice.actions

export default { loadingReducer: loadingSlice.reducer } 
