import './App.css';
import { createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import Overview from './pages/Overview/Overview';
import History from './pages/History/History';
import Withdraw from './pages/Withdraw/Withdraw';
import Help from './pages/Help/Help';
import Manage from './pages/Manage/Manage';
import Orders from './pages/Orders/Orders';
import RequireAuth from './app/core/security/RequireAuth/RequireAuth';
import Unauthorized from './pages/Unauthorized/Unauthorized';
import { SecurityRole } from './app/core/security/SecurityRole';
import Notification from './app/components/Notification/Notification';
import useNotification from './hooks/useNotification';
import Loading from './app/components/Loading/Loading';
import NotFound from './pages/NotFound/NotFound';
import RouterProvider from './app/core/providers/RouterProvider';
import StandingOffer from './pages/StandingOffer/StandingOffer';
import Modifications from './pages/Modifications/Modifications';
import { setTranslations, setDefaultLanguage, setLanguage } from 'react-multi-lang'
import cz from './translations/cz.json'
import de from './translations/de.json'
import us from './translations/en.json'
import hu from './translations/hu.json'
import pl from './translations/pl.json'
import sk from './translations/sk.json'

import { useSelector } from 'react-redux';
import { RootState } from './app/core/store/store';
import { useEffect } from 'react';
import Admin from './pages/Admin/Admin';
import { SocketProvider } from './app/context/websocketContext';
import Layout from './app/containers/Layout/Layout';
import Login from './pages/Login/Login';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'react-datetime-picker/dist/DateTimePicker.css';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import OrdersBoard from './pages/OrdersBoard/OrdersBoard';
import DotyposConnect from './pages/DotyposConnect/DotyposConnect';

const black = '#000000';
const white = '#FFFFFF';
const scalva = '#FAFD5D';
const secondary = black; // '#83BF9F';
const ketchup = '#AD191E';
const ketchupDark = '#8A1418';
const ketchupLight = '#FBE9E9';
const mustard = '#F6BC2F';
const mustardDark = '#C59626';
const mustardLight = '#FEF8EA';
const blue = '#36C5F0';
const blueDark = '#2B9EC0';
const blueLight = '#EBF9FE';
const green = '#007A5A';
const greenDark = '#004936';
const greenLight = '#E9FFEF';
const grey = '#A0A0A2';
const greyDark = '#343434';
const greyMedium = '#5A585E';
const greyLight = '#F1F2F6';
const offWhite = '#FAFAFA';
const disabled = '#E8E8E8';


declare module '@mui/material/IconButton' {
  interface IconButtonPropsColorOverrides {
    natural: true;
    black: true;
    white: true;
    scalva: true;
    ketchup: true;
    ketchupDark: true;
    ketchupLight: true;
    mustard: true;
    mustardDark: true;
    mustardLight: true;
    blue: true;
    blueDark: true;
    blueLight: true;
    green: true;
    greenDark: true;
    greenLight: true;
    grey: true;
    greyDark: true;
    greyMedium: true;
    greyLight: true;
    offWhite: true;
    disabled: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    natural: true;
    black: true;
    white: true;
    scalva: true;
    ketchup: true;
    ketchupDark: true;
    ketchupLight: true;
    mustard: true;
    mustardDark: true;
    mustardLight: true;
    blue: true;
    blueDark: true;
    blueLight: true;
    green: true;
    greenDark: true;
    greenLight: true;
    grey: true;
    greyDark: true;
    greyMedium: true;
    greyLight: true;
    offWhite: true;
    disabled: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    border: {
      main: string,
      secondary: string
    };
  }
  interface PaletteOptions {
    border: {
      main: string,
      secondary: string
    };
  }
  interface Palette {
    ketchup: {
      main: string
    },
    scalva: {
      main: string;
    }
    mustard: {
      main: string
    },
    black: {
      main: string
    },
    white: {
      main: string
    },
    blue: {
      main: string
    },
    green: {
      main: string
    },

    // DARK
    ketchupDark: {
      main: string
    },
    mustardDark: {
      main: string
    },
    blueDark: {
      main: string
    },
    greenDark: {
      main: string
    },
    greyDark: {
      main: string
    },

    // MEDIUM
    greyMedium: {
      main: string
    },

    // LIGHT
    ketchupLight: {
      main: string
    },
    mustardLight: {
      main: string
    },
    blueLight: {
      main: string
    },
    greenLight: {
      main: string
    },
    greyLight: {
      main: string
    },
    offWhite: {
      main: string
    },
    disabled: {
      main: string
    },
    positive: {
      main: string
    },
    negative: {
      main: string
    },
    positiveDark: {
      main: string
    },
    warningDark: {
      main: string
    },
    infoDark: {
      main: string
    },
    negativeDark: {
      main: string
    },
    positiveLight: {
      main: string
    },
    warningLight: {
      main: string
    },
    infoLight: {
      main: string
    },
    negativeLight: {
      main: string
    }
  }
  interface PaletteOptions {
    ketchup: {
      main: string
    },
    scalva: {
      main: string;
    }
    mustard: {
      main: string
    },
    black: {
      main: string,
      contrastText: string
    },
    white: {
      main: string,
      contrastText: string
    },
    blue: {
      main: string
    },
    green: {
      main: string
    },

    // DARK
    ketchupDark: {
      main: string
    },
    mustardDark: {
      main: string
    },
    blueDark: {
      main: string
    },
    greenDark: {
      main: string
    },
    greyDark: {
      main: string
    },

    // MEDIUM
    greyMedium: {
      main: string
    },

    // LIGHT
    ketchupLight: {
      main: string
    },
    mustardLight: {
      main: string
    },
    blueLight: {
      main: string
    },
    greenLight: {
      main: string
    },
    greyNormal: {
      main: string
    },
    greyLight: {
      main: string
    },
    offWhite: {
      main: string
    },
    disabled: {
      main: string
    },
    positive: {
      main: string
    },
    negative: {
      main: string
    },
    positiveDark: {
      main: string
    },
    warningDark: {
      main: string
    },
    infoDark: {
      main: string
    },
    negativeDark: {
      main: string
    },
    positiveLight: {
      main: string
    },
    warningLight: {
      main: string
    },
    infoLight: {
      main: string
    },
    negativeLight: {
      main: string
    }
  }
  interface Palette {
    natural: {
      main: string
    };
    ketchup: {
      main: string
    },
    scalva: {
      main: string;
    },
    mustard: {
      main: string
    },

    blue: {
      main: string
    },
    green: {
      main: string
    },

    // DARK
    ketchupDark: {
      main: string
    },
    mustardDark: {
      main: string
    },
    blueDark: {
      main: string
    },
    greenDark: {
      main: string
    },
    greyDark: {
      main: string
    },

    // MEDIUM
    greyMedium: {
      main: string
    },

    // LIGHT
    ketchupLight: {
      main: string
    },
    mustardLight: {
      main: string
    },
    blueLight: {
      main: string
    },
    greenLight: {
      main: string
    },
    greyNormal: {
      main: string
    },
    greyLight: {
      main: string
    },
    offWhite: {
      main: string
    },
    disabled: {
      main: string
    },
    positive: {
      main: string
    },
    negative: {
      main: string
    },
    positiveDark: {
      main: string
    },
    warningDark: {
      main: string
    },
    infoDark: {
      main: string
    },
    negativeDark: {
      main: string
    },
    positiveLight: {
      main: string
    },
    warningLight: {
      main: string
    },
    infoLight: {
      main: string
    },
    negativeLight: {
      main: string
    }
  }
  interface PaletteOptions {
    natural: {
      main: string,
      contrastText: string
    };
    black: {
      main: string,
      contrastText: string
    };
    white: {
      main: string,
      contrastText: string
    };
  }
}


let brambleTheme = createTheme({
  components: {
    // MuiMenuItem: {
    //   styleOverrides: {
    //     root: {
    //       "&.Mui-selected": {
    //         // backgroundColor: scalva
    //       },
    //     }
    //   }
    // },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        sizeSmall: {
          textTransform: 'none',
          padding: '8px 10px',
          fontWeight: '600',
          borderRadius: '5000px'
        },
        sizeMedium: {
          textTransform: 'none',
          padding: '11px 20px',
          borderRadius: '5000px',
          fontWeight: '600',
          borderWidth: '2px !important'
        },
        sizeLarge: {
          textTransform: 'none',
          padding: '14px 24px',
          fontWeight: '600',
          borderRadius: '5000px'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: "#6b6b6b #2b2b2b",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "white",
            width: "10px"
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            backgroundColor: "rgba(149,149,149,0.5)",
            minHeight: 24
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
            backgroundColor: "#959595",
          },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
            backgroundColor: "#959595",
          },
          // "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
          //   backgroundColor: "#2b2b2b",
          // },
        },
      },
    },
  },
  typography: {
    fontFamily: [
      'Inter', 'Roboto', 'Arial',
    ].join(','),
  },
  palette: {
    // mode: 'dark',
    primary: {
      main: scalva,
      contrastText: black
    },
    secondary: {
      main: secondary,
      contrastText: white
    },
    warning: {
      main: mustard,
      contrastText: white
    },
    natural: {
      main: '#615E83',
      contrastText: white
    },
    black: {
      main: black,
      contrastText: white
    },
    white: {
      main: white,
      contrastText: black
    },
    scalva: {
      main: scalva
    },
    ketchup: {
      main: ketchup
    },
    mustard: {
      main: mustard
    },
    blue: {
      main: blue
    },
    green: {
      main: green
    },
    greyNormal: {
      main: grey
    },

    // DARK
    ketchupDark: {
      main: ketchupDark
    },
    mustardDark: {
      main: mustardDark
    },
    blueDark: {
      main: blueDark
    },
    greenDark: {
      main: greenDark
    },
    greyDark: {
      main: greyDark
    },

    // MEDIUM
    greyMedium: {
      main: greyMedium
    },

    // LIGHT
    ketchupLight: {
      main: ketchupLight
    },
    mustardLight: {
      main: mustardLight
    },
    blueLight: {
      main: blueLight
    },
    greenLight: {
      main: greenLight
    },
    greyLight: {
      main: greyLight
    },
    offWhite: {
      main: offWhite
    },
    disabled: {
      main: disabled
    },
    positive: {
      main: green
    },
    negative: {
      main: ketchup
    },
    positiveDark: {
      main: greenDark
    },
    warningDark: {
      main: mustardDark
    },
    infoDark: {
      main: blueDark
    },
    negativeDark: {
      main: ketchupDark
    },
    positiveLight: {
      main: greenLight
    },
    warningLight: {
      main: mustardLight
    },
    infoLight: {
      main: blueLight
    },
    negativeLight: {
      main: ketchupLight
    },
    border: {
      main: '#D81F27',
      secondary: '#E6E6E6'
    },
  }
});

brambleTheme = responsiveFontSizes(brambleTheme);

// Do this two lines only when setting up the application
setTranslations({
  cz,
  de,
  us,
  hu,
  pl,
  sk
})

setDefaultLanguage('us');

export default function App() {

  const { message, severity, autoHideDuration, messageId } = useNotification();

  const language = useSelector((state: RootState) => state.ui.language);
  const auth = useSelector((state: RootState) => state.auth);

  // set default language based on current browser location on app init 
  // useEffect(() => {
  //   getGeoInfo();
  // }, []);

  // set global language on all store changes
  useEffect(() => {
    setLanguage(language?.toLocaleLowerCase());
  });

  // async function getGeoInfo() {
  //   await axios.get('https://ipapi.co/json/').then((response) => {
  //     let data = response.data;
  //     setDefaultLanguage(data?.country_code?.toLocaleLowerCase() || 'us');
  //   }).catch((error) => {
  //     console.log(error);
  //   });
  // };

  return (
    <>
      <RouterProvider>
        <SocketProvider>
          <ThemeProvider theme={brambleTheme}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
            >
              <Loading />
              <Layout>
                <Routes>
                  <Route
                    path="/"
                    element={<Navigate to="/login" />}
                  />
                  <Route path="*" element={<NotFound />} />

                  <Route path='/forget-password' element={<ForgetPassword />} />
                  <Route path='/reset-password' element={<ResetPassword />} />
                  <Route path='/login' element={<Login />} />
                  {/* <Route path='/register' element={<Register />} />
                <Route path='/festival/register' element={<FestivalRegister />} />
                <Route path='/restaurant/register' element={<RestaurantRegister />} /> */}
                  <Route path='/unauthorized' element={<Unauthorized />} />

                  <Route>
                    <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]} />}>
                      <Route path='/overview' element={<Overview />} />
                    </Route>

                    {auth?.user?.restaurantId && <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]} />}>
                      <Route path='/orders-board' element={<OrdersBoard />} />
                    </Route>}

                    {auth?.user?.restaurantId && <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]} />}>
                      <Route path='/overview/orders' element={<Orders />} />
                    </Route>}

                    {auth?.user?.restaurantId && <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]} />}>
                      <Route path='/history' element={<History />} />
                    </Route>}

                    {/* <Route element={<RequireAuth allowedRoles={[SecurityRole.Admin, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]} />}>
                  <Route path='/daily-menu' element={<DailyMenu />} />
                </Route> */}

                    {auth?.user?.restaurantId && <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]} />}>
                      <Route path='/standing-offer' element={<StandingOffer />} />
                    </Route>}

                    {auth?.user?.restaurantId && <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]} />}>
                      <Route path='/modifications' element={<Modifications />} />
                    </Route>}

                    {/* <Route path='/menu-settings' element={<MenuSettings />} /> */}
                    {/* <Route element={<RequireAuth allowedRoles={[SecurityRole.Admin, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]} />}>
                  <Route path='/sales' element={<Sales />} />
                </Route> */}

                    <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager]} />}>
                      <Route path='/withdraw' element={<Withdraw />} />
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager]} />}>
                      <Route path='/manage' element={<Manage />} />
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager]} />}>
                      <Route path='/dotypos-connect' element={<DotyposConnect />} />
                    </Route>

                    {/* <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner]} />}>
                    <Route path='/stripe-connect' element={<StripeConnect />} />
                  </Route> */}

                    <Route element={<RequireAuth allowedRoles={[SecurityRole.Admin]} />}>
                      <Route path='/admin' element={<Admin />} />
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]} />}>
                      <Route path='/help' element={<Help />} />
                    </Route>
                  </Route>
                </Routes>
              </Layout>
              <Notification key={messageId} message={message} severity={severity} autoHideDuration={autoHideDuration!} />
            </LocalizationProvider>
          </ThemeProvider>
        </SocketProvider>
      </RouterProvider>
    </>
  )
}