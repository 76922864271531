import { SecurityRole } from "../SecurityRole";
import { useSelector } from 'react-redux';
import { RootState } from "../../store/store";


export interface PermissionData {
    allowedRoles: SecurityRole[];
    children: any;
}

export const HasPermission = ({ allowedRoles, children }: PermissionData) => {

    const auth = useSelector((state: RootState) => state.auth)

    let isAllowed = allowedRoles?.indexOf(auth?.user?.securityRoleId as SecurityRole) > -1; // do some permission check

    
    if (isAllowed) {
        return children; // rendering nested elements
    } else {
        /*
         it also might be false, empty string or empty array
         (and for React 18 it can be `return;` or `return undefined` as well);
         also you even can omit explicit `return undefined;` for `else` branch
         but this way intention is more clear
        */
        return null;
    }
}