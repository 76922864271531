import axios from '../app/core/api/axios';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../app/core/store/store';


const useRefreshToken = () => {

    // const dispatch = useDispatch();
    // const auth = useSelector((state: RootState) => state.auth)

    const refresh = async () => {
        const response = await axios.post('/api/auth/refresh', {
            // accessToken: auth.accessToken,
            // refreshToken: auth.refreshToken
        }, { withCredentials: true });


        // dispatch(updateToken({
        //     accessToken: response.data.accessToken,
        //     refreshToken: response.data.refreshToken
        // }));

        // return response.data.accessToken;
    }
    return refresh;
};

export default useRefreshToken;
