import { combineReducers, configureStore } from '@reduxjs/toolkit'
// import storage from 'redux-persist/es/storage'
import storage from 'redux-persist/lib/storage'
import authReducer from './features/auth/authSlice'
import notificationReducer from './features/notification/notificationSlice'
import categoryReducer from './features/category/categorySlice'
import extraCategoryReducer from './features/extraCategory/extraCategorySlice'
import orderReducer from './features/order/orderSlice'
import restaurantReducer from './features/restaurant/restaurantSlice'
import loadingReducer from './features/loading/loadingSlice'
import sidePanelReducer from './features/sidePanel/sidePanelSlice'
import uiReducer from './features/ui/uiSlice'
import thunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt';

// TODO
const KEY = 'secret key 123';

const encrypt = encryptTransform({
  secretKey: KEY,
  onError: function (error) {
    // Handle the error.
  },
})

const persistConfig: any = {
  key: 'root',
  storage: storage, // storageSession,
  whitelist: ['auth', 'ui'],
  transforms: [encrypt]
}

const rootReducer = combineReducers({
  auth: authReducer.authReducer,
  notification: notificationReducer.notificationReducer,
  category: categoryReducer.categoryReducer,
  extraCategory: extraCategoryReducer.extraCategoryReducer,
  order: orderReducer,
  restaurant: restaurantReducer.restaurantReducer,
  loading: loadingReducer.loadingReducer,
  sidePanel: sidePanelReducer.sidePanelReducer,
  ui: uiReducer.uiReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk]
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const persistor = persistStore(store)