import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Divider, Menu } from '@mui/material';
import { useEffect } from 'react';


export type MenuData = {
  mouseX: number;
  mouseY: number;
  data: any;
  dataType: any;
};

export type Item = {
  icon?: React.ReactNode,
  type: "actionItem" | "divider",
  label?: string;
  onAction?: (event: any, item: any, data: any, dataType: any) => void;
};

export type ContextMenuProps = {
  data: MenuData | null;
  open: boolean;
  items: Item[];
  onClose: (event: any) => void;
};

export default function ContextMenu(props: ContextMenuProps) {

  const [position, setPosition] = React.useState<any>({
    left: props?.data?.mouseX,
    top: props?.data?.mouseY
  });

  const [data, setData] = React.useState<any>(props?.data?.data);
  const [dataType, setDataType] = React.useState<any>(props?.data?.dataType);

  // TODO: refresh context data on contextmenu event 
  useEffect(() => {
    setPosition({
      left: props?.data?.mouseX,
      top: props?.data?.mouseY
    });

    setData(props?.data?.data);
    setDataType(props?.data?.dataType);
  }, [props?.data])

  function handleClose(event: any) {
    props?.onClose(event);
  }

  return (
    <>
      {position?.top && position?.left && <Menu
        id="context-menu"
        anchorReference="anchorPosition"
        anchorPosition={position}
        open={props.open}
        PaperProps={{  
          style: {  
            width: 180,  
          },  
       }} 
        onClose={handleClose}
        onContextMenu={(e) => {
          e.preventDefault();
          handleClose(e);
          // setPosition({
          //   left: e.clientX + 2,
          //   top: e.clientY - 6
          // })
        }}
        MenuListProps={{
          'aria-labelledby': 'lock-button',
          role: 'listbox',
        }}
      >
        {props.items.map((item, index) => {
          if (item.type === 'actionItem') {
            return <MenuItem key={index} onClick={(e) => { item.onAction?.(e, item, data, dataType); handleClose(e); }}>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          } else {
            return <Divider key={index} />
          }
        }

        )}
      </Menu>}
    </>
  );
}