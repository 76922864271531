import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { Chip, Grid, IconButton, ImageList, ImageListItem, ImageListItemBar, ListSubheader, MenuItem, Select, Stack, styled, TextField, useTheme } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploading from 'react-files-uploading';
import { Menuitem } from '../../shared/interfaces/menuitem.interface';
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import { useSelector } from 'react-redux';
import { RootState } from '../../core/store/store';
import { ExtraCategory } from '../../shared/interfaces/extra-category.interface';
import { useTranslation } from 'react-multi-lang';
import { MINIO_URL } from '../../core/api/axios';
import { StyledTextField } from '../../components/StyledComponents/StyledComponents';


const ExtraCategoryToggleButton = styled(ToggleButton)(({ theme }) => ({
    textTransform: "none",
    background: theme.palette.offWhite.main,
    border: '1px solid #EBEBF0',
    padding: '4px 8px',
    height: '42px',
    minWidth: '60px',
    borderRadius: '1000px',
    marginRight: '5px',
    marginTop: '5px'
}));


const DietToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    gridGap: "10px",
    padding: "10px",
    ".MuiToggleButtonGroup-grouped": {
        border: '1px solid #EBEBF0 !important',
        borderRadius: '4px !important'
    }
}));

const AllergensBox = styled(Box)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(30px, 1fr))",
    justifyContent: "start"
}));

const TaxToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    display: "grid",
    gridTemplateColumns: "auto auto auto",
    justifyContent: "start",
    gridGap: "10px",
    padding: "10px",
    ".MuiToggleButtonGroup-grouped": {
        border: '1px solid #EBEBF0 !important',
        borderRadius: '4px !important'
    }
}));


export interface MenuItemEditorProps {
    data: Menuitem;
    onChangeData: (data: any) => void;
}

export default function MenuItemEditor(props: MenuItemEditorProps) {

    const theme = useTheme();

    const [name, setName] = useState(props?.data?.name || "");
    const [description, setDescription] = useState(props?.data?.description || "");
    const [weight, setWeight] = useState(parseWeight(props?.data?.weight) || 0);
    const [price, setPrice] = useState(props?.data?.price?.toLocaleString() || "0");
    const [taxRate, setTaxRate] = useState(props?.data?.taxRate || 0);
    const [discountPrice, setDiscountPrice] = useState(props?.data?.discountPrice?.toLocaleString() || "0");
    const [weightUnit, setWeightUnit] = useState(parseWeightUnit(props?.data?.weight) || "g");
    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [diet, setDiet] = useState<string | null>(null);
    const [thumbnail, setThumbnail] = useState<string>("../../../../src/assets/image_placeholder.png");
    const [isPopular, setIsPopular] = useState(props?.data?.isPopular || false);
    const [isRecommended, setIsRecommended] = useState(props?.data?.isRecommended || false);

    const [extraCategories, setExtraCategories] = useState<ExtraCategory[]>([]);
    const [extraCategoriesMap, setExtraCategoriesMap] = useState<any>({});

    const [nameError, setNameError] = useState("");
    const [discountPriceError, setDiscountPriceError] = useState("");
    const [priceError, setPriceError] = useState("");

    const axiosSecure = useAxiosSecure();
    const auth = useSelector((state: RootState) => state.auth);
    const restaurant = useSelector((state: RootState) => state.auth.restaurant);
    const t = useTranslation();


    let _parsedAllergens = parseAllergens(props?.data?.allergens);
    let _allergens: any = {};

    [...Array(14)].map((x, i) => {
        if (_parsedAllergens?.indexOf(`${i + 1}`) > -1) {
            _allergens[(i + 1)] = true;
        } else {
            _allergens[(i + 1)] = false;
        }
    });

    const [allergens, setAllergens] = useState<{
        [k: number]: boolean;
    }>(_allergens);


    useEffect(() => {
        async function apiCall() {

            let filter = "";

            if (auth.user) {
                filter += `restaurantId=${auth.user?.restaurantId}&`;
            }

            let response = await axiosSecure.get(`/api/extra_category?${filter}`, true);

            setExtraCategories(response.data)

            let _map: any = {};
            response.data.reduce(function (result: any, currentObject: any) {
                _map[currentObject.id] = false;
                return result;
            }, {});

            let selectedExtraCategories = props?.data?.menuitemExtraCategories?.map(i => i.extraCategoryId);

            for (const [key, value] of Object.entries(_map)) {
                if (selectedExtraCategories?.indexOf(key) > -1) {
                    _map[key] = true;
                } else {
                    _map[key] = false;
                }
            }

            setExtraCategoriesMap(_map);
        }

        apiCall();
    }, [])


    useEffect(() => {
        setName(props?.data?.name || "");
        setDescription(props?.data?.description || "");
        setWeight(parseWeight(props?.data?.weight) || 0);
        setPrice(props?.data?.price?.toLocaleString() || "0");
        setDiscountPrice(props?.data?.discountPrice?.toLocaleString() || "0");
        setTaxRate(props?.data?.taxRate || 0);
        setDiet(props?.data?.diet?.toString() || null);
        setWeightUnit(parseWeightUnit(props?.data?.weight) || "g");
        setFileToUpload(null);
        setThumbnail(`${MINIO_URL}/menuitems/${props?.data?.id}`);
        setIsPopular(props?.data?.isPopular || false);
        setIsRecommended(props?.data?.isRecommended || false);

        let selectedExtraCategories = props?.data?.menuitemExtraCategories?.map(i => i.extraCategoryId);

        for (const [key, value] of Object.entries(extraCategoriesMap)) {
            if (selectedExtraCategories?.indexOf(key) > -1) {
                extraCategoriesMap[key] = true;
            } else {
                extraCategoriesMap[key] = false;
            }
        }

        setExtraCategoriesMap(extraCategoriesMap);

        let _parsedAllergens = parseAllergens(props?.data?.allergens);
        let _allergens: any = {};

        [...Array(14)].map((x, i) => {
            if (_parsedAllergens?.indexOf(`${i + 1}`) > -1) {
                _allergens[(i + 1)] = true;
            } else {
                _allergens[(i + 1)] = false;
            }
        });
        setAllergens(_allergens)

    }, [props?.data])


    useEffect(() => {

        var checkedAllergens = Object.entries(allergens).filter(([k, v]) => v)?.map(([k, v]) => k)?.join(',');
        var checkedExtraCategories = Object.entries(extraCategoriesMap).filter(([k, v]) => v)?.map(([k, v]) => k);

        props?.onChangeData({
            isValid: validateForm(),
            id: props?.data?.id,
            name,
            description,
            weight: `${weight}${weightUnit}`,
            allergens: checkedAllergens,
            price: parseFloat(price.replace(',', '.') as string),
            discountPrice: parseFloat(discountPrice.replace(',', '.') as string),
            taxRate,
            categoryId: props?.data?.categoryId,
            viewOrder: props?.data?.viewOrder || 0,
            isHidden: false,
            isPopular,
            isRecommended,
            diet: diet == null ? -1 : +diet,

            image: fileToUpload ? fileToUpload : null,
            _extraCategoryIds: checkedExtraCategories
        });

    }, [name, description, weight, weightUnit, allergens, price, discountPrice, taxRate, diet, isPopular, isRecommended, extraCategoriesMap, fileToUpload])

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!name) {
            setNameError("* Required");
            isValid = false;
        } else {
            setNameError("");
        }

        let _price = parseFloat(price.replace(',', '.') as string);
        let _discountPrice = parseFloat(discountPrice.replace(',', '.') as string);

        if (_price < _discountPrice) {
            setDiscountPriceError("* Discount price must be lower than price");
            isValid = false;
        } else {
            setDiscountPriceError("");
        }

        // if (_discountPrice != 0 && _discountPrice < 0.5) {
        //     setDiscountPriceError("* Minimum is 0.5");
        //     isValid = false;
        // } else {
        //     if (discountPriceError != "") {
        //         setDiscountPriceError("");
        //     }
        // }

        // if (_price < 0.5) {
        //     setPriceError("* Minimum is 0.5");
        //     isValid = false;
        // } else {
        //     setPriceError("");
        // }

        return isValid;
    };

    const handleAllergenChange = (event: any) => {
        let _allergens = { ...allergens };
        _allergens[event?.target?.value] = !_allergens[event?.target?.value];

        setAllergens(_allergens);
    }

    const handleTaxRateChange = (
        event: React.MouseEvent<HTMLElement>,
        newTaxRate: number | null,
    ) => {
        if (newTaxRate != null) {
            setTaxRate(newTaxRate)
        };
    };

    const handlePopularChange = (
        event: React.MouseEvent<HTMLElement>,
        value: string | null,) => {
        setIsPopular(!value);
    }

    const handleRecommendedChange = (
        event: React.MouseEvent<HTMLElement>,
        value: string | null,) => {
        setIsRecommended(!value);
    }

    const handleDietChange = (
        event: React.MouseEvent<HTMLElement>,
        newDiet: string | null,
    ) => {
        setDiet(newDiet);
    };

    function parseWeight(weight: string | undefined) {
        var res = weight?.replace(/[^\d.-]/g, "");
        return res || "";
    }

    function parseWeightUnit(weight: string | undefined) {
        var res = weight?.replace(/[\W\d]/g, "");
        return res || "";
    }

    function parseAllergens(allergens: string | undefined) {
        var res = allergens?.split(",") || [];
        return res;
    }

    function handleNameChange(event: any) {
        setName(event.target.value);
    }

    function handleDescriptionChange(event: any) {
        setDescription(event.target.value);
    }

    function handlePriceChange(event: any) {
        var value = event.target.value?.replace(/[^0-9$.,]/g, '')
        setPrice(value);
    }

    function handleDiscountPriceChange(event: any) {
        var value = event.target.value?.replace(/[^0-9$.,]/g, '')
        if (value === "") {
            value = "0";
        }
        setDiscountPrice(value);
    }

    function handleWeightChange(event: any) {
        setWeight(event.target.value);
    }

    function handleWeightUnitChange(event: any) {
        setWeightUnit(event.target.value);
    }

    function handleFileChange(fileList: File[]) {
        setFileToUpload(fileList[0]);
        const objectUrl = URL.createObjectURL(fileList[0]);
        setThumbnail(objectUrl);
        // setPreview(objectUrl)

        // free memory when ever this component is unmounted
        // return () => URL.revokeObjectURL(objectUrl)
    };

    function handleRemoveMenuitemThumbnailClick(event: any) {
        event.stopPropagation();
        setFileToUpload(null);
        setThumbnail('');
    }

    function handleExtraCategoryChange(event: any) {
        let _extraCategoriesMap = { ...extraCategoriesMap };
        _extraCategoriesMap[event?.target?.value] = !_extraCategoriesMap[event?.target?.value];
        setExtraCategoriesMap(_extraCategoriesMap);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography><strong>{t('containers.editors.menuitem.labels.name')}</strong></Typography>
                <StyledTextField
                    variant="outlined"
                    color="secondary"
                    value={name}
                    onChange={handleNameChange}
                    error={!!nameError}
                    helperText={nameError}
                />
            </Grid>

            <Grid item xs={8}>
                <Typography><strong>{t('containers.editors.menuitem.labels.description')}</strong></Typography>
                <StyledTextField
                    variant="outlined"
                    color="secondary"
                    multiline
                    rows={4}
                    value={description}
                    onChange={handleDescriptionChange}
                />
            </Grid>

            <Grid item xs={4}>
                <Typography><strong>{t('containers.editors.menuitem.labels.image')}</strong></Typography>
                <FileUploading value={fileToUpload != null ? [fileToUpload] : []} onChange={handleFileChange}
                    acceptType={['jpg', 'jpeg', 'png']} maxFileSize={2097152}>
                    {({
                        fileList,
                        errors,
                        isDragging,
                        onFileUpload,
                        onFileRemoveAll,
                        onFileUpdate,
                        onFileRemove,
                        dragProps,
                    }) => {
                        return (
                            <>
                                <Box
                                    id="btn-upload"
                                    onClick={onFileUpload}
                                    {...dragProps}
                                    sx={styles.fileUploadContainer}
                                >
                                    {errors && errors.maxNumber && (
                                        <span>Number of selected files exceed maxNumber</span>
                                    )}

                                    <>
                                        <Stack
                                            spacing={2}
                                            direction="column"
                                            justifyContent="space-between"
                                            sx={styles.fileUploadLabelContainer}
                                        >
                                            <Typography noWrap sx={styles.fileUploadLabel}>
                                                {t('containers.editors.menuitem.labels.chooseImage')}
                                            </Typography>

                                            {fileToUpload != null && (
                                                <Stack
                                                    spacing={2}
                                                    direction="row"
                                                    justifyContent="space-between"
                                                >
                                                    <Typography sx={styles.fileName}>
                                                        {fileToUpload?.name}
                                                    </Typography>

                                                    <DeleteIcon
                                                        onClick={(e) => handleRemoveMenuitemThumbnailClick(e)} />
                                                </Stack>)}
                                        </Stack>
                                        <img
                                            style={{
                                                filter: "brightness(70%)",
                                                borderRadius: "5px",
                                                height: "100%",
                                                width: "100%",
                                                objectFit: "cover"
                                            }}
                                            src={thumbnail}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = require("../../../../src/assets/image_placeholder.png");
                                            }}
                                        />

                                    </>

                                </Box>
                            </>
                        );
                    }}
                </FileUploading>
            </Grid>

            <Grid item xs={12}>
                <Stack
                    direction="row"
                    spacing={2}
                >
                    <ToggleButton
                        color="secondary"
                        value={isPopular}
                        selected={isPopular}
                        onChange={handlePopularChange}
                    >
                        Is Popular
                    </ToggleButton>

                    <ToggleButton
                        color="secondary"
                        value={isRecommended}
                        selected={isRecommended}
                        onChange={handleRecommendedChange}
                    >
                        Is Recommended
                    </ToggleButton>
                </Stack>
            </Grid>

            <Grid item xs={6}>
                <Typography><strong>{t('containers.editors.menuitem.labels.price')}</strong></Typography>
                <StyledTextField
                    variant="outlined"
                    color="secondary"
                    value={price}
                    error={!!priceError}
                    helperText={priceError}
                    onChange={handlePriceChange}
                />
            </Grid>

            <Grid item xs={6}>
                <Typography><strong>{t('containers.editors.menuitem.labels.salePrice')}</strong></Typography>
                <StyledTextField
                    variant="outlined"
                    color="secondary"
                    value={discountPrice}
                    error={!!discountPriceError}
                    helperText={discountPriceError}
                    onChange={handleDiscountPriceChange}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography><strong>{t('containers.editors.extraMenuitem.labels.taxRate')}</strong></Typography>
                <TaxToggleButtonGroup
                    value={taxRate}
                    exclusive
                    onChange={handleTaxRateChange}
                >
                    {restaurant?.taxRates.map((tax, i) => (
                        <ToggleButton
                            key={tax.id}
                            sx={styles.taxButton}
                            color="secondary"
                            value={tax.taxRate}
                        >
                            {`${tax.taxRate} %`}
                        </ToggleButton>
                    ))}
                </TaxToggleButtonGroup>
            </Grid>

            <Grid item xs={12}>
                <Typography><strong>{t('containers.editors.menuitem.labels.size')}</strong></Typography>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        background: 'white',
                        width: '100%'
                    }}
                >
                    <StyledTextField
                        type="number"
                        value={weight}
                        variant="outlined"
                        color="secondary"
                        onChange={handleWeightChange}
                    />
                    <Select
                        value={weightUnit}
                        color="secondary"
                        onChange={handleWeightUnitChange}
                    >
                        <MenuItem value="mg">mg</MenuItem>
                        <MenuItem value="g">g</MenuItem>
                        <MenuItem value="dag">dag</MenuItem>
                        <MenuItem value="kg">kg</MenuItem>
                        <MenuItem value="lb">lb</MenuItem>
                        <MenuItem value="oz">oz</MenuItem>
                        <MenuItem value="q">q</MenuItem>
                        <MenuItem value="t">t</MenuItem>
                        <MenuItem value="l">l</MenuItem>
                        <MenuItem value="ml">ml</MenuItem>
                        <MenuItem value="pcs">pcs</MenuItem>
                    </Select>
                </Stack>
            </Grid>

            <Grid item xs={12}>
                <Typography>
                    <strong>{t('containers.editors.menuitem.labels.diet')}</strong>
                </Typography>

                <DietToggleButtonGroup
                    value={diet}
                    exclusive
                    onChange={handleDietChange}
                    aria-label="Platform"
                >
                    <ToggleButton value="0" color="secondary">Vegan</ToggleButton>
                    <ToggleButton value="1" color="secondary">Vegetarian</ToggleButton>
                    <ToggleButton value="2" color="secondary">Gluten-free</ToggleButton>
                </DietToggleButtonGroup>
            </Grid>

            <Grid item xs={12}>

                <Typography>
                    <strong>{t('containers.editors.menuitem.labels.allergens')}</strong>
                </Typography>

                <AllergensBox>
                    {[...Array(14)].map((x, i) => (
                        <Grid item xs key={i + 1}>

                            <ToggleButton
                                sx={styles.allergenButton}
                                color="secondary"
                                value={i + 1}
                                selected={allergens[(i + 1)]}
                                onChange={handleAllergenChange}
                            >
                                {(i + 1)}
                            </ToggleButton>

                        </Grid>
                    ))}
                </AllergensBox>

            </Grid>

            <Grid item xs={12}>
                <>
                    <Typography>
                        <strong>{t('containers.editors.menuitem.labels.modifications')}</strong>
                    </Typography>

                    <Box sx={styles.extraCategories}>
                        {extraCategories.map((extraCategory, index) => (
                            <ExtraCategoryToggleButton
                                key={index}
                                color="secondary"
                                value={extraCategory?.id}
                                selected={extraCategoriesMap[extraCategory?.id]}
                                onChange={handleExtraCategoryChange}
                            >
                                {extraCategory.name}
                            </ExtraCategoryToggleButton>
                        ))}
                    </Box>
                </>
            </Grid>
        </Grid>
    );
}


const styles = {
    fileUploadContainer: {
        background: 'white',
        position: 'relative',
        color: '#AEAEB2', // theme.palette.primary.main,
        borderRadius: '8px',
        border: `3px dashed #AEAEB2`, // ${theme.palette.primary.main}`,
        height: '125px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'filter ease-in-out 200ms',
        ':hover': {
            filter: 'brightness(0.85)'
        }
    },
    fileUploadLabelContainer: {
        position: 'absolute',
        zIndex: 1,
        color: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    fileUploadLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fileName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '100px'
    },
    allergenButton: {
        width: "20px",
        height: "20px"
    },
    taxButton: {
        width: "fit-content",
        height: "20px"
    },
    extraCategories: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}