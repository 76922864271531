
export enum OrderStateEnum {
    IsPreparing = "24026a19-19c7-41d1-8c7b-b66a312ef890",
    IsDone = "52e282c1-2c60-4711-ad11-a542c1033ed0",
    IsCanceled = "b9a8a558-3e58-4dda-bd83-d8afad571f7a",
    IsCreated = "e261ece3-3cd8-4e8b-b4e6-3efc9d239790",
    IsPickedUp = "700fe815-98dc-4f6e-9845-70c1015a7f1f"
}


export interface OrderState {
    id: string;
    name: string;
    codeName: string;
    createdAt: string;
    updatedAt: string;
}