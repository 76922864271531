import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import useAxiosSecure from "../../hooks/useAxiosSecure";


export default function ForgetPassword() {

    const t = useTranslation();
    const navigate = useNavigate();
    const axiosSecure = useAxiosSecure();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");


    useEffect(() => {
        validateForm();
    }, [email])

    // Validation functions
    const validateForm = () => {
        let isValid = true;

        if (!email || !isValidEmail(email)) {
            setEmailError("Invalid email address");
            isValid = false;
        } else {
            setEmailError("");
        }

        return isValid;
    };

    const isValidEmail = (email: string) => {
        // Implement your email validation logic here
        return /\S+@\S+\.\S+/.test(email);
    };

    function handleEmailChange(event: any) {
        setEmail(event.target.value);
    }

    async function handleForget(event: any) {
        validateForm();

        let response = await axiosSecure.post(`/api/auth/forget_password`, {
            email
        }, false);

        navigate("/login");
    }

    return (
        <>
            <Stack
                spacing={2}
                alignItems="center"
                sx={styles.container}
            >
                <Box>
                    <Stack spacing={2} alignItems="center">
                        <Typography><strong>{t('pages.forgetPassword.labels.email')}</strong></Typography>

                        <TextField
                            variant="outlined"
                            color="secondary"
                            value={email}
                            onChange={handleEmailChange}
                            error={!!emailError}
                            helperText={emailError}
                        />

                        <Button variant="contained" onClick={handleForget}>
                            {t('pages.forgetPassword.actions.forget')}
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </>

    );
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    bold: {
        fontSize: 'small',
        fontWeight: 'bold'
    }
}