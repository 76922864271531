import { Button, Card, CardActions, CardContent, Stack, Theme, Typography, useTheme } from "@mui/material";
import Grid from '@mui/material/Grid';
import { setPanelContext } from "../../app/core/store/features/sidePanel/sidePanelSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/core/store/store";
import { useEffect, useState } from "react";


export default function Manage() {

    const restaurant = useSelector((state: RootState) => state.auth.restaurant);
    const user = useSelector((state: RootState) => state.auth.user);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);

    const theme = useTheme();
    const styles = makeStyles(theme);

    const dispatch = useDispatch();

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    return (
        <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
        >
            <Grid container item xs={isMobile ? 12 : 6} direction="column" >
                <Card sx={{ ...styles.cardContainer }} onClick={() => {
                    dispatch(setPanelContext({
                        action: 'UPDATE',
                        contextType: 'Profile',
                        contextObject: user
                    }));
                }}>
                    <CardContent>
                        <Typography variant="h6" color="text.secondary" gutterBottom>
                            Me
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Manage profile
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            {restaurant && <>
                <Grid container item xs={isMobile ? 12 : 6} direction="column" >
                    <Card sx={{ ...styles.cardContainer }} onClick={() => {
                        dispatch(setPanelContext({
                            action: 'UPDATE',
                            contextType: 'Restaurant',
                            contextObject: restaurant,
                            extraData: {
                                isFromManage: true,
                                showIsPopular: false,
                                showDelete: false
                            }
                        }));
                    }}>
                        <CardContent>
                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                My Restaurant
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Manage restaurant
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid container item xs={isMobile ? 12 : 6} direction="column" >
                    <Card sx={{ ...styles.cardContainer }} onClick={() => {
                        dispatch(setPanelContext({
                            action: 'UPDATE',
                            contextType: 'Staff',
                            contextObject: {}
                        }));
                    }}>
                        <CardContent>
                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                Staff
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Manage restaurant staff
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                {/* <Grid container item xs={isMobile ? 12 : 6} direction="column" >
                    <Card sx={{ ...styles.cardContainer }} onClick={() => {
                        dispatch(setPanelContext({
                            action: 'UPDATE',
                            contextType: 'Dotypos',
                            contextObject: {}
                        }));
                    }}>
                        <CardContent>
                            <Typography variant="h6" color="text.secondary" gutterBottom>
                                Dotykačka
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                Manage Dotykačka
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid> */}
            </>}
        </Grid>
    );
}

const makeStyles = (theme: Theme) => {
    return {
        cardContainer: {
            //aspectRatio: "1/ 1",
            borderRadius: "4px",
            // width: 260,
            border: `1px solid ${theme.palette.greyLight.main}`,
            // textAlign: "center",
            padding: "16px",
            margin: "5px",
            display: 'flex',
            justiyContent: 'space-between',
            flexDirection: 'column',
            boxShadow: 'none',
            ':hover': {
                boxShadow: 15,
                cursor: 'pointer'
            }
        },
        cardContent: {
            padding: 0,
            height: "100%"
        },
        bold24: {
            fontSize: 24,
            fontWeight: 900
        }
    }
};
