import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LoginIcon from '@mui/icons-material/Login';
import { HasPermission } from '../../core/security/HasPermission/HasPermission';
import { SecurityRole } from '../../core/security/SecurityRole';
import { IsLoggedIn } from '../../core/security/IsLoggedIn/IsLoggedIn';
import { useDispatch, useSelector } from 'react-redux';
import { IsNotLoggedIn } from '../../core/security/IsNotLoggedIn/IsNotLoggedIn';
import { RootState } from '../../core/store/store';
import { useLocation } from 'react-router-dom';
import { Badge, IconButton, InputAdornment, Popover, Stack, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import EjectOutlinedIcon from '@mui/icons-material/EjectOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import useAxiosSecure from '../../../hooks/useAxiosSecure';
import { useEffect, useState } from 'react';
import SidePanel from '../SidePanel/SidePanel';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { useTranslation } from 'react-multi-lang';
import { reset as resetAuth, updateAuth, updateUser, updateUserRestaurant } from '../../core/store/features/auth/authSlice';
import { reset as resetPanel, setPanelOpen } from '../../core/store/features/sidePanel/sidePanelSlice';
import LanguageSelect from '../../components/LanguageSelect/LanguageSelect';
import MenuNavBar from '../../components/MenuNavBar/MenuNavBar';
import SideMenuItem from '../../components/SideMenuItem/SideMenuItem';
import TuneIcon from '@mui/icons-material/Tune';
import LockIcon from '@mui/icons-material/Lock';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import HelpDialog from '../../dialogs/HelpDialog/HelpDialog';
import { IsRestaurantOpen } from '../../core/security/IsRestaurantOpen/IsRestaurantOpen';
import { reset as resetUi, setCancelOrderMode, setOrderDateRange, setOrderSearch, setOrderStateIds } from '../../core/store/features/ui/uiSlice';
import { useDebounce } from 'usehooks-ts'
import '@iroomit/react-date-range/dist/styles.css'; // main style file
import '@iroomit/react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from '@iroomit/react-date-range';
import { reset as resetCategory } from '../../core/store/features/category/categorySlice';
import { reset as resetExtraCategory } from '../../core/store/features/extraCategory/extraCategorySlice';
import { reset as resetOrder } from '../../core/store/features/order/orderSlice';
import { reset as resetRestaurant } from '../../core/store/features/restaurant/restaurantSlice';
import SetPasswordDialog from '../../dialogs/SetPasswordDialog/SetPasswordDialog';
import FilterDialog, { FilterDialogEvent } from '../../dialogs/FilterDialog/FilterDialog';


const drawerWidth = 240;

const shadowMixin = (theme: Theme): CSSObject => ({
    boxShadow: '0px 8px 9px -5px rgb(0 0 0 / 20%), 0px 15px 22px 2px rgb(0 0 0 / 14%), 0px 6px 28px 5px rgb(0 0 0 / 12%);',
});

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflow: 'hidden',
    // '& .MuiListItemButton-root': {

    //     '& .MuiListItemIcon-root': {
    //         color: theme.palette.primary.main
    //     }
    // }
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    borderRight: `1px solid ${theme.palette.greyLight.main}`,
    overflow: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`
});

const MenuDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': { ...openedMixin(theme), ...shadowMixin(theme) },
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const SidePanelDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        overflow: 'scroll',
        '& .MuiPaper-root': {
            top: 83.5,
            height: 'fill-available',
            overflow: 'auto !important'
        },
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

const NavigationList = styled(List)(({ theme }) => ({
    '& .MuiListItem-root': {
        padding: 0,
        margin: 0
    },
    '&& .Mui-selected, && .Mui-selected:hover': {
        // background: 'linear-gradient(180deg, #FFEEF1 0.64%, #FFC6CF 102.46%)',
        background: theme.palette.primary.main,
        borderRight: `3px solid ${theme.palette.primary.main}`,
        // borderRadius: '10px',
        color: theme.palette.black.main,

        '& .MuiListItemIcon-root': {
            color: theme.palette.black.main
        },
    },
    // hover states
    '& .MuiListItemButton-root:hover': {
        background: theme.palette.black.main,
        color: theme.palette.white.main,

        '& .MuiListItemIcon-root': {
            color: theme.palette.white.main
        },
    },
    '& .MuiListItemButton-root': {
        color: theme.palette.black.main,

        '& .MuiListItemIcon-root': {
            color: theme.palette.black.main
        }
    }
}));

const BrambleInput = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        borderColor: 'none',
        borderRadius: 4,
        background: theme.palette.greyLight.main,
    },
    '& .MuiOutlinedInput-root .MuiOutlinedInput-input': {
        padding: '12px 16px'
    },
}));


export default function Layout(props: any) {

    const [open, setOpen] = useState(false);
    const [isHelpOpen, setIsHelpOpen] = useState(false);
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [isOnboardedOpen, setIsOnboardedOpen] = useState(false);
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
    const [dateRange, setDateRange] = useState<any>();

    const currentUser = useSelector((state: RootState) => state?.auth?.user);
    const auth = useSelector((state: RootState) => state.auth);
    const cancelOrderMode = useSelector((state: RootState) => state.ui.cancelOrderMode);
    const orderSearch = useSelector((state: RootState) => state.ui.orderSearch);
    const showDeleted = useSelector((state: RootState) => state.ui.showDeleted);
    const orderStateIds = useSelector((state: RootState) => state.ui.orderStateIds);

    const [orderSearchValue, setOrderSearchValue] = useState(orderSearch);

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const orderDateRange = useSelector((state: RootState) => state.ui.orderDateRange);
    const debouncedOrderSearchValue = useDebounce<string>(orderSearchValue, 300)

    const filterOpen = Boolean(anchorEl);
    const sidePanel = useSelector((state: RootState) => state.sidePanel);

    const location = useLocation();
    const dispatch = useDispatch();
    const axiosSecure = useAxiosSecure();
    const theme = useTheme();
    const styles = makeStyles(theme);
    const t = useTranslation();



    useEffect(() => {
        if (currentUser && !currentUser?.isOnboarded) {
            setIsOnboardedOpen(true);
        }
    }, [currentUser]);

    useEffect(() => {
        // console.log(orderDateRange)

        // if (orderDateRange && orderDateRange?.length > 0) {
        //     setDateRange([
        //         {
        //             startDate: dayjs(orderDateRange[0]).utc().toDate(),
        //             endDate: dayjs(orderDateRange[1]).utc().toDate(),
        //             key: "selection",
        //         },
        //     ])
        // } else {
        //     setDateRange([
        //         {
        //             startDate: dayjs().utc().toDate(),
        //             endDate: dayjs().utc().toDate(),
        //             key: "selection",
        //         },
        //     ])
        // }
    }, []);

    // useEffect(() => {
    //     console.log(orderDateRange)
    //     if (orderDateRange) {
    //         // dispatch(setOrderDateRange([dateRange[0].startDate, dateRange[0].endDate]))
    //     }
    // }, [orderDateRange]);

    useEffect(() => {
        dispatch(setOrderSearch(debouncedOrderSearchValue));
    }, [debouncedOrderSearchValue]);

    const handleMouseOver = () => {
        if (!isMobile) {
            setOpen(true);
        }
    };

    const handleMouseOut = () => {
        setOpen(false)
    };

    function handleWindowSizeChange() {
        setIsMobile(window.innerWidth <= 768);
    }

    function handleLogOut() {
        dispatch(updateAuth({
            accessToken: null,
            refreshToken: null,
            user: null,
            restaurant: null,
            festival: null
        }));

        dispatch(setPanelOpen(false));
        setOpen(false)

        dispatch(resetAuth);
        dispatch(resetCategory);
        dispatch(resetExtraCategory);
        dispatch(resetOrder);
        dispatch(resetPanel);
        dispatch(resetRestaurant);
        dispatch(resetUi);
    }

    async function handleRestaurantClose() {

        let _restaurant = ({ ...auth.restaurant });

        let response = await axiosSecure.put(`/api/restaurant/open`,
            {
                id: _restaurant.id,
                isOpen: false
            }, true);

        if (response?.data && response?.data?.length > 0) {
            dispatch(updateUserRestaurant({
                restaurant: response?.data[0]
            }));
        }
    }

    function handleOpenHelp() {
        setIsHelpOpen(true);
    }

    function handleCancelOrderMode() {
        dispatch(setCancelOrderMode(!cancelOrderMode));
    }

    function handleFilterOpen(event: React.MouseEvent<HTMLButtonElement>) {
        // setAnchorEl(event.currentTarget);
        setIsFilterOpen(true);
    };

    const handleFilterClose = () => {
        setAnchorEl(null);
    };

    async function handleRestaurantOverloaded() {

        let _restaurant = ({ ...auth.restaurant });
        let _isOverloaded = !_restaurant.isOverloaded;

        let response = await axiosSecure.put(`/api/restaurant/overloaded`,
            {
                id: _restaurant.id,
                isOverloaded: _isOverloaded
            }, false);

        if (response?.data && response?.data?.length > 0) {
            dispatch(updateUserRestaurant({
                restaurant: response?.data[0]
            }));
        }
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);


    function renderNavigation(path: string) {
        switch (path) {
            case '/overview':
                return (<Stack direction="row" justifyContent="start" spacing={1}><Typography color={'black'} sx={styles.headerLabel}>{t('containers.layout.labels.orders')}</Typography></Stack>);
            case '/overview/orders':
                return (
                    <Stack direction="row" justifyContent="start" alignItems="center" spacing={2}
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Typography color={'black'} sx={styles.headerLabel}>{t('containers.layout.labels.orders')}</Typography>
                        <BrambleInput
                            sx={{
                                width: '100%'
                            }}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                            }}
                            color="secondary"
                            label={'Hľadať objednávku'}
                            name="name"
                            value={orderSearchValue}
                            onChange={handleSearchOrderChange}
                        />

                        <Badge color="secondary" overlap="circular" badgeContent=" " variant="dot">
                            <IconButton
                                sx={styles.iconButton}
                                onClick={handleFilterOpen}
                            >
                                <TuneIcon />
                            </IconButton>
                        </Badge>

                        <Popover
                            open={filterOpen}
                            anchorEl={anchorEl}
                            onClose={handleFilterClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <DateRangePicker
                                onChange={(item: any) => { setDateRange([item.selection]) }}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                ranges={dateRange}
                                color={theme.palette.secondary.main}
                                rangeColors={[theme.palette.ketchup.main]}
                                direction="vertical"
                            />
                        </Popover>
                    </Stack>
                );
            case '/history':
                return (<Stack direction="row" justifyContent="start" spacing={1}><Typography color={'black'} sx={styles.headerLabel}>{t('containers.layout.labels.history')}</Typography></Stack>);
            // case '/daily-menu':
            //     return (<Stack direction="row" justifyContent="start" spacing={1} ><TodayIcon /> <Typography><strong>{t('DailyMenu')}</strong></Typography></Stack>);
            case '/standing-offer':
                return (<Stack direction="row" justifyContent="start" spacing={1}><Typography sx={styles.headerLabel}>{t('containers.layout.labels.standingOffer')}</Typography></Stack>);
            case '/modifications':
                return (<Stack direction="row" justifyContent="start" spacing={1}><Typography sx={styles.headerLabel}>{t('containers.layout.labels.modifications')}</Typography></Stack>);
            case '/manage':
                return (<Stack direction="row" justifyContent="start" spacing={1}> <Typography sx={styles.headerLabel}>{t('containers.layout.labels.manage')}</Typography></Stack>);
            case '/dotypos-connect':
                return (<Stack direction="row" justifyContent="start" spacing={1}> <Typography sx={styles.headerLabel}>{t('Dotypos')}</Typography></Stack>);
            case '/admin':
                return (<Stack direction="row" justifyContent="start" spacing={1}> <Typography sx={styles.headerLabel}>{t('containers.layout.labels.admin')}</Typography></Stack>);
            // case '/sales':
            //     return (<Stack direction="row" justifyContent="start" spacing={1}><BusinessCenterOutlinedIcon /> <Typography><strong>{t('Sales')}</strong></Typography></Stack>);
            case '/help':
                return (<Stack direction="row" justifyContent="start" spacing={1}><Typography sx={styles.headerLabel}>{t('containers.layout.labels.help')}</Typography></Stack>);
            default:
                return (<Stack direction="row" justifyContent="start" spacing={1}><Typography sx={styles.headerLabel}>{t('containers.layout.labels.navigation')}</Typography></Stack>);
        }
    }

    function handleSidePanel(open: boolean) {
        dispatch(setPanelOpen(open));
    }

    function handleSearchOrderChange(event: any) {
        var value = event.target.value;
        setOrderSearchValue(value);
    }

    function handleFilterDialogClose(event: FilterDialogEvent) {
        setIsFilterOpen(false);
        // TODO
        dispatch(setOrderStateIds(event.orderStateIds));
        dispatch(setOrderDateRange(event.dateRange));
    }

    async function handleSetNewPasword(event: any) {
        setIsOnboardedOpen(false);

        if (event) {
            let response = await axiosSecure.put(`/api/auth/user_onboard`, {
                id: currentUser?.id,
                password: event["password"]
            }, true);

            if (response?.data && response?.data?.length > 0) {
                dispatch(updateUser({
                    user: response?.data[0]
                }));
            }
        }
    }

    function getWidth() {
        if (isMobile) return '100%';
        if (['/manage'].indexOf(location?.pathname) > -1) return '60%';
        // if (['/overview/orders'].indexOf(location?.pathname) > -1) return '70%';
        if ([/*'/daily-menu',*/ '/standing-offer', '/modifications', '/admin'].indexOf(location?.pathname) > -1) return '50%';
        return '100%';
    }

    function getPanelWidth() {
        if (isMobile) return '100%';
        if (['/manage'].indexOf(location?.pathname) > -1) return '40%';
        // if (['/overview/orders'].indexOf(location?.pathname) > -1) return '30%';
        if ([/*'/daily-menu',*/ '/standing-offer', '/modifications', '/admin'].indexOf(location?.pathname) > -1) return '50%';
        return '0%';
    }

    return (
        <>

            {isHelpOpen && <HelpDialog
                open={isHelpOpen}
                isMobile={isMobile}
                okLabel={t('dialogs.actions.close')}
                onClose={() => setIsHelpOpen(false)}
            />}


            {isFilterOpen && <FilterDialog
                open={isFilterOpen}
                isMobile={isMobile}
                selectedDateRange={orderDateRange}
                selectedOrderStateIds={orderStateIds}
                okLabel={t('dialogs.actions.close')}
                onClose={handleFilterDialogClose}
            />}

            {!currentUser?.isOnboarded && isOnboardedOpen && <SetPasswordDialog
                open={isOnboardedOpen}
                isMobile={isMobile}
                title={'Welcome to Bramble!'}
                okLabel='Set'
                cancelLabel='Cancel'
                onClose={handleSetNewPasword}
            />}

            <Box sx={styles.container}>
                <CssBaseline />

                <IsLoggedIn>
                    {location?.pathname !== '/orders-board' && <>

                        <MenuDrawer
                            variant="permanent"
                            open={open}
                            onMouseOver={handleMouseOver}
                            onMouseOut={handleMouseOut}
                        >
                            <Box sx={{
                                overflowY: open ? 'auto' : 'hidden',
                                ...styles.drawerContainer
                            }}>
                                <div style={{
                                    position: 'relative',
                                    marginBottom: '16px',
                                    height: '62px',
                                    width: 'auto'
                                }}>
                                    <img
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            borderRadius: '50%',
                                            margin: '15px 0px 0px 5px',
                                            height: "47px",
                                            opacity: open ? 0 : 1, // Image fades out when open
                                            transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
                                            transform: open ? 'scale(0.9)' : 'scale(1)', // Slight scale effect
                                        }}
                                        src={require("../../../../src/assets/logo_small.png")}
                                        alt="small logo"
                                    />

                                    <img
                                        style={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            margin: '12px 15px 0px 15px',
                                            height: "62px",
                                            opacity: open ? 1 : 0, // Image fades in when open
                                            transition: 'opacity 0.5s ease-in-out, transform 0.5s ease-in-out',
                                            transform: open ? 'scale(1)' : 'scale(0.9)', // Slight scale effect
                                        }}
                                        src={require("../../../../src/assets/logo.png")}
                                        alt="large logo"
                                    />
                                </div>

                                <IsNotLoggedIn>
                                    <NavigationList>

                                        <SideMenuItem text={t('Login')} link='/login' open={open}><LoginIcon /></SideMenuItem>

                                    </NavigationList>
                                </IsNotLoggedIn>

                                <HasPermission allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen, SecurityRole.Potman]}>
                                    <NavigationList>

                                        <SideMenuItem text={t('containers.layout.labels.orders')} link='/overview' open={open}><HomeOutlinedIcon /></SideMenuItem>
                                        {auth?.user?.restaurantId && <SideMenuItem text={t('containers.layout.labels.history')} link='/history' open={open}><RefreshIcon /></SideMenuItem>}
                                        {auth?.user?.restaurantId && <SideMenuItem text={t('containers.layout.labels.wallboard')} link='/orders-board' target='_blank' open={open}><ViewModuleIcon /></SideMenuItem>}

                                    </NavigationList>
                                </HasPermission>

                                {auth?.user?.restaurantId && <HasPermission allowedRoles={[SecurityRole.Owner, SecurityRole.Manager]}>
                                    <Divider variant="middle" />
                                    <NavigationList>

                                        {/* <SideMenuItem text={t('DailyMenu')} link='/daily-menu' open={open}><TodayIcon /></SideMenuItem> */}
                                        <SideMenuItem text={t('containers.layout.labels.standingOffer')} link='/standing-offer' open={open}><ImportContactsIcon /></SideMenuItem>
                                        <SideMenuItem text={t('containers.layout.labels.modifications')} link='/modifications' open={open}><EjectOutlinedIcon /></SideMenuItem>

                                    </NavigationList>
                                </HasPermission>}


                                <HasPermission allowedRoles={[SecurityRole.Owner, SecurityRole.Manager]}>
                                    <Divider variant="middle" />
                                    <NavigationList>
                                        <SideMenuItem text={t('containers.layout.labels.manage')} link='/manage' open={open}><BusinessCenterOutlinedIcon /></SideMenuItem>
                                    </NavigationList>
                                </HasPermission>

                                <HasPermission allowedRoles={[SecurityRole.Owner, SecurityRole.Manager]}>
                                    <Divider variant="middle" />
                                    <NavigationList>
                                        <SideMenuItem text={t('Dotykačka')} link='/dotypos-connect' open={open}><PointOfSaleIcon /></SideMenuItem>
                                    </NavigationList>
                                </HasPermission>

                                {/* <HasPermission allowedRoles={[SecurityRole.Owner]}>
                            <NavigationList>
                                <SideMenuItem text={t('StripeConnect')} link='/stripe-connect' open={open}><MonetizationOnIcon /></SideMenuItem>
                            </NavigationList>
                        </HasPermission> */}

                                <HasPermission allowedRoles={[SecurityRole.Admin]}>
                                    <NavigationList>
                                        <SideMenuItem text={t('containers.layout.labels.admin')} link='/admin' open={open}><AdminPanelSettingsIcon /></SideMenuItem>
                                    </NavigationList>
                                </HasPermission>
                            </Box>

                            <NavigationList
                                sx={{
                                    marginTop: 'auto',
                                    bottom: '0',
                                    background: 'white'
                                }}
                            >

                                <HasPermission allowedRoles={[SecurityRole.Owner, SecurityRole.Manager]}>

                                    <IsRestaurantOpen>

                                        <SideMenuItem text={t('containers.layout.actions.close')} link='#' onClick={handleRestaurantClose} open={open}><LockIcon /></SideMenuItem>

                                    </IsRestaurantOpen>
                                </HasPermission>

                                <SideMenuItem text={t('containers.layout.labels.help')} link='#' open={open} onClick={handleOpenHelp}><HelpOutlineOutlinedIcon /></SideMenuItem>

                                <LanguageSelect
                                    text={t('languageSelect.language')}
                                    open={open}
                                    onMouseOver={handleMouseOver}
                                    onMouseOut={handleMouseOut}
                                />

                                <SideMenuItem text={t('containers.layout.labels.logout')} link='/login' open={open} onClick={handleLogOut}><LogoutOutlinedIcon /></SideMenuItem>
                            </NavigationList>
                        </MenuDrawer>
                    </>}
                </IsLoggedIn>

                <IsLoggedIn>

                    {location?.pathname !== '/orders-board' && <>
                        {/* // TODO: make header component */}
                        <Stack
                            sx={styles.header}
                            direction={isMobile ? "column" : "row"}
                            alignItems="center"
                            justifyContent="space-between"
                            spacing={2}
                        >
                            {renderNavigation(location?.pathname)}

                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                spacing={2}
                            >
                                <HasPermission allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen]}>
                                    <Button
                                        sx={{
                                            height: 50
                                        }}
                                        color={cancelOrderMode ? "secondary" : "greyLight"}
                                        variant="contained"
                                        onClick={handleCancelOrderMode}
                                    >
                                        <Typography noWrap color={cancelOrderMode ? theme.palette.white.main : theme.palette.black.main} sx={{
                                            fontSize: {
                                                lg: "large",
                                                md: "medium",
                                                sm: "small"
                                            }
                                        }}>
                                            {cancelOrderMode ? t('containers.layout.actions.closeCancelOrder') : t('containers.layout.actions.cancelOrder')}
                                        </Typography>
                                    </Button>
                                </HasPermission>

                                <HasPermission allowedRoles={[SecurityRole.Owner, SecurityRole.Manager, SecurityRole.Kitchen]}>
                                    <IconButton
                                        sx={styles.iconButton}
                                        onClick={handleRestaurantOverloaded}
                                    >
                                        {auth.restaurant?.isOverloaded && <PlayArrowOutlinedIcon />}
                                        {!auth.restaurant?.isOverloaded && <PauseIcon />}
                                    </IconButton>
                                </HasPermission>

                                <IconButton
                                    sx={styles.iconButton}
                                    onClick={handleOpenHelp}
                                >
                                    <HelpOutlineOutlinedIcon />
                                </IconButton>
                            </Stack>
                        </Stack>

                        {['/overview/orders'].indexOf(location?.pathname) > -1 && <>
                            {auth.restaurant?.isOverloaded && <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={styles.actionAlert}
                            >
                                <Typography sx={styles.overloadedText}>{t('containers.layout.labels.overloadedService')}</Typography>
                            </Stack>}

                            {cancelOrderMode && <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={styles.actionAlert}
                            >
                                <Typography sx={styles.overloadedText}>{t('containers.layout.labels.cancelOrder')}</Typography>
                            </Stack>}
                        </>}
                    </>}

                    {/* TODO */}
                    {[/*'/daily-menu',*/ '/standing-offer', '/modifications'].indexOf(location?.pathname) > -1 &&
                        <Box
                            sx={{
                                width: getWidth()
                            }}
                        >
                            <MenuNavBar showDeleted={showDeleted} />
                        </Box>
                    }

                </IsLoggedIn>

                <Box component="main"
                    sx={{
                        width: getWidth(),
                        // if location is orders board, set padding 0px
                        padding: (currentUser != null && location?.pathname !== '/orders-board') ? `24px 24px 24px calc(${theme.spacing(10)} + 1px)` : (currentUser == null ? '24px 24px 24px 24px' : '0px'),
                        ...styles.main,
                        bgcolor: location?.pathname !== '/orders-board' ? theme.palette.offWhite.main : theme.palette.white.main
                    }}>

                    <Box sx={{
                        margin: location?.pathname == '/orders-board' ? '0px' : '20px'
                    }}>{props.children}</Box>

                    {isMobile && <MuiDrawer
                        open={sidePanel.isOpen}
                        variant="temporary"
                        anchor="bottom"
                        onClose={() => handleSidePanel(false)}
                    >
                        <SidePanel />
                    </MuiDrawer>}

                    {!isMobile && <SidePanelDrawer
                        sx={{
                            width: sidePanel.isOpen ? getPanelWidth() : '0%',
                            flexShrink: 0,
                            '& .MuiDrawer-paper': {
                                width: sidePanel.isOpen ? getPanelWidth() : '0%',
                            },
                            '& .MuiPaper-root': {
                                // top: auth.restaurant?.isOverloaded ? 139.5 : 83.5
                                top: 83.5
                            },
                        }}
                        open={sidePanel.isOpen}
                        variant="permanent"
                        anchor="right"
                    >
                        <SidePanel />
                    </SidePanelDrawer>}
                </Box>

            </Box >
        </>

    );
}

const makeStyles = (theme: Theme) => {
    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh'
        },
        drawerContainer: {
            padding: 0,
            overflowX: 'hidden'
        },
        header: {
            zIndex: 1002,
            padding: `16px 16px 16px calc(${theme.spacing(13)} + 1px)`,
            background: theme.palette.offWhite.main,
            borderBottom: '1px solid #E0E0E0'
        },
        headerLabel: {
            fontSize: {
                lg: 32,
                md: 32,
                sm: 16
            },
            fontWeight: 900,
        },
        actionAlert: {
            padding: `16px 16px 16px calc(${theme.spacing(13)} + 1px)`,
            backgroundColor: theme.palette.secondary.main
        },
        iconButton: {
            background: theme.palette.greyLight.main,
            color: theme.palette.black.main,
            height: 50,
            width: 50
        },
        overloadedText: {
            lineHeight: '24px',

            color: theme.palette.white.main,
            fontWeight: 400,
            fontSize: {
                lg: "large",
                md: "medium",
                sm: "small"
            }
        },
        main: {
            flexGrow: 1,
            // bgcolor: theme.palette.offWhite.main,
            borderRight: '1px solid #E0E0E0',
            overflowY: 'auto',
            overflowX: 'hidden',
            height: 0,
        }
    }
}