import { useEffect, useState, createContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import useWebSocket, { ReadyState } from 'react-use-websocket'
import PubSub from '../pubsub'

import { debounce } from 'lodash';
import { BASE_URL } from '../core/api/axios';
import { upsertOrder } from '../core/store/features/order/orderSlice';
import { RootState, store } from '../core/store/store';

export const SocketContext = createContext<any | null>({})

let proto = '';
if (window.location.protocol === 'http:') {
  proto = window.location.protocol.replace('http', 'ws')
} else {
  proto = window.location.protocol.replace('https', 'wss')
}

const wsAddress = `${proto}//${BASE_URL.replace('https://', '').replace('http://', '')}/ws`

export const SocketProvider = (props: any) => {

  const [topics, setTopics] = useState<string[]>([`order.update`, `order.place`])
  const dispatch = useDispatch();


  const wsOpts = {
    shouldReconnect: () => {
      return true
    },
  }

  const { sendMessage, readyState, getWebSocket } = useWebSocket(wsAddress, wsOpts)


  const subscribe = () => {
    sendMessage(
      JSON.stringify({
        topic: 'auth',
        // token: localStorage.getItem('accessToken'),
        subscribe: topics
      }),
    )
  }

  useEffect(() => {
    subscribe()
  }, [topics])

  const updateTopicsDebounce = debounce((newTopics: any) => {
    console.log('WS: Subscriptions changed')
    setTopics(newTopics)
  }, 200)

  PubSub.setOnSubscriptionsChange((newTopics: any) => updateTopicsDebounce(newTopics))

  const onMessage = (message: any) => {
    const data = JSON.parse(message.data)
    if (data.topic === 'heartbeat') return

    if (data.sender === window.senderId) {
      return // my own message. ignore
    }
    // if (data.topic === 'shout' && data?.summary?.text) toast.info(data.summary.text)

    // console.log('Event RX', data)
    const state = store.getState();

    if (data?.event && data?.event?.restaurantId === state.auth?.user?.restaurantId) {
      dispatch(upsertOrder(data.event));
    }

    // PubSub.publish(data.topic, data)
  }

  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      getWebSocket()!.onmessage = onMessage
      subscribe()
      // Dispatch a fake event to the frontend components
      // in case they depend on the event stream and may
      // miss some messages - this should force reloading
      // events using graphql
      PubSub.publish('client.connected', {
        topic: 'client.connected',
      })
    }
  }, [readyState, getWebSocket])

  return (
    <SocketContext.Provider
      value={{
        getWebSocket,
        readyState
      }}
    >
      {props.children}
    </SocketContext.Provider>
  )
}
