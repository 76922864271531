import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


export interface RouterContextProps{
  to: string;
  from: string;
}

export const RouterContext = createContext({} as RouterContextProps);

const RouterProvider = (props: { children: any }) => {
  const location = useLocation()
  const [route, setRoute] = useState<RouterContextProps>({
    to: location.pathname,
    from: location.pathname
  });

  useEffect(() => {
    setRoute((prev) => ({ to: location.pathname, from: prev.to }))
  }, [location]);

  return <RouterContext.Provider value={route}>
    {props.children}
  </RouterContext.Provider>
}

export default RouterProvider;