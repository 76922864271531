
import useAxiosSecure from '../../hooks/useAxiosSecure';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { RootState } from '../../app/core/store/store';
import { Category } from '../../app/shared/interfaces/category.interface';
import { Checkbox, FormControlLabel, useTheme } from '@mui/material';
import { addCategories } from '../../app/core/store/features/category/categorySlice';
import CategoryList from '../../app/containers/CategoryList/CategoryList';


export default function StandingOffer() {

    const auth = useSelector((state: RootState) => state.auth);
    const showDeleted = useSelector((state: RootState) => state.ui.showDeleted);

    const standingOfferCategories = useSelector((state: RootState) => [...state.category.categories])
        .filter(c => c.isStandingOffer && c.restaurantId === auth.user?.restaurantId)
        .sort((a: Category, b: Category) => a.viewOrder - b.viewOrder);


    const axiosSecure = useAxiosSecure();
    const dispatch = useDispatch();
    const theme = useTheme();

    useEffect(() => {
        async function apiCall() {

            let filter = "";

            if (auth.user) {
                filter += `restaurantId=${auth.user?.restaurantId}&`;
            }

            let response = await axiosSecure.get(`/api/category?${filter}`, true);

            dispatch(addCategories(response.data));
        }

        apiCall();
    }, [])


    return (
        <>
            <CategoryList
                categories={standingOfferCategories}
                showDeleted={showDeleted}
            />
        </>
    );
}