import { useSelector } from 'react-redux';
import { RootState } from "../../store/store";


export interface PermissionData {
    children: any;
}

export const IsNotLoggedIn = ({ children }: PermissionData) => {

    const auth = useSelector((state: RootState) => state.auth)

    let isAllowed = auth.user === null;
    

    if (isAllowed) {
        return children; // rendering nested elements
    } else {
        /*
         it also might be false, empty string or empty array
         (and for React 18 it can be `return;` or `return undefined` as well);
         also you even can omit explicit `return undefined;` for `else` branch
         but this way intention is more clear
        */
        return null;
    }
}