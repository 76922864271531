import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-multi-lang';

import styles from './create-menuitem-button.module.css';

export type CreateMenuItemButtonProps = {
    onClick: (event: any) => void;
};

export default function CreateMenuItemButton(props: CreateMenuItemButtonProps) {
    
    const t = useTranslation();

    return (
        <Stack
            className={styles.createMenuitem}
            onClick={props?.onClick}
        >
            <Typography
                sx={{
                    fontSize: 'small'
                }}
                align="center"
            >
                {t('containers.editors.category.actions.add')}
            </Typography>
        </Stack>
    );

}