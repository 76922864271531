import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { OrderStateEnum } from '../../../../shared/interfaces/order-state.interface';


export interface UiState {
    language: string;
    cancelOrderMode: boolean;
    orderSearch: string;
    showDeleted: boolean;
    orderDateRange: string;
    orderStateIds: string[];
}

const initialState: UiState = {
    language: "sk",
    cancelOrderMode: false,
    showDeleted: false,
    orderSearch: "",
    orderDateRange: "today",
    orderStateIds: [OrderStateEnum.IsCreated, OrderStateEnum.IsPreparing, OrderStateEnum.IsDone]
}

export const uiSlice = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        reset: () => initialState,
        setLanguage: (state, action: PayloadAction<string>) => {
            state.language = action.payload;
        },
        setCancelOrderMode: (state, action: PayloadAction<boolean>) => {
            state.cancelOrderMode = action.payload;
        },
        setOrderSearch: (state, action: PayloadAction<string>) => {
            state.orderSearch = action.payload;
        },
        setShowDeleted: (state, action: PayloadAction<boolean>) => {
            state.showDeleted = action.payload;
        },
        setOrderDateRange: (state, action: PayloadAction<string>) => {
            state.orderDateRange = action.payload;
        },
        setOrderStateIds: (state, action: PayloadAction<string[]>) => {
            state.orderStateIds = action.payload;
        }
    },
})

// Action creators are generated for each case reducer function
export const { reset, setLanguage, setCancelOrderMode, setOrderSearch, setShowDeleted, setOrderDateRange, setOrderStateIds } = uiSlice.actions

export default { uiReducer: uiSlice.reducer } 
