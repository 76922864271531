import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { User } from '../../../../shared/interfaces/user.interfaces';
import { Restaurant } from '../../../../shared/interfaces/restaurant.interface';
import { Festival } from '../../../../shared/interfaces/festival.interface';


export interface AuthState {
    accessToken?: string | null;
    refreshToken?: string | null;
    user?: User | null;
    restaurant?: Restaurant | null;
    festival?: Festival | null;
}

const initialState: AuthState = {
    accessToken: null,
    refreshToken: null,
    user: null,
    restaurant: undefined,
    festival: undefined
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: () => initialState,
        updateAuth: (state, action: PayloadAction<Partial<AuthState>>) => {
            //state.accessToken = action.payload.accessToken;
            //state.refreshToken = action.payload.refreshToken;
            state.user = action.payload.user;
            state.restaurant = action.payload.restaurant;
            state.festival = action.payload.festival;
        },
        updateToken: (state, action: PayloadAction<Partial<AuthState>>) => {
            //state.accessToken = action.payload.accessToken;
            //state.refreshToken = action.payload.refreshToken;
        },
        updateUser: (state, action: PayloadAction<Partial<AuthState>>) => {
            state.user = action.payload.user;
        },
        updateUserRestaurant: (state, action: PayloadAction<Partial<AuthState>>) => {
            state.restaurant = action.payload.restaurant;
        },
        updateUserFestival: (state, action: PayloadAction<Partial<AuthState>>) => {
            state.festival = action.payload.festival;
        },
        updateStripeOnboarded: (state, action: PayloadAction<boolean>) => {
            if (state.user) {
                state.user.stripeOnboarded = action.payload;
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { reset, updateAuth, updateToken, updateUser, updateUserRestaurant, updateStripeOnboarded } = authSlice.actions

export default { authReducer: authSlice.reducer }
