import api from "../../../api/axios";


export interface LoginRequestSchema {
    login: string;
    password: string;
}

export interface RegisterRequestSchema {
    id: string;
    login: string;
    email: string;
    password1: string;
    password2: string;
    firstName: string;
    lastName: string;
    phone: string;
    street: string;
    zipCode: string;
    city: string;
    state: string;
    securityRoleId: string;
}

export async function login(input: LoginRequestSchema) {
    const response = await api.post(`/api/auth/login`, input, { withCredentials: true });
    return response.data;
}

export async function register(input: RegisterRequestSchema) {
    const response = await api.post(`/api/user/register`, input);
    return response.data;
}