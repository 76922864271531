import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { Stack } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploading from 'react-files-uploading';



export interface FileUploadProps {
    label: string;
    sourceUrl: string;
    defaultSourceUrl: string;
    onChangeData: (data: any) => void;
}

export default function FileUpload(props: FileUploadProps) {


    const [fileToUpload, setFileToUpload] = useState<File | null>(null);
    const [preview, setPreview] = useState<string>(props.defaultSourceUrl);


    useEffect(() => {
        setPreview(props.sourceUrl);
    }, [])


    useEffect(() => {

        props?.onChangeData({
            file: fileToUpload ? fileToUpload : null
        });

    }, [fileToUpload])

    function handleFileChange(fileList: File[]) {
        setFileToUpload(fileList[0]);
        const objectUrl = URL.createObjectURL(fileList[0]);
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        // return () => URL.revokeObjectURL(objectUrl)
    };

    function handleRemoveFileClick(event: any) {
        event.stopPropagation();
        setFileToUpload(null);
        setPreview('');
    }


    return (
        <FileUploading value={fileToUpload != null ? [fileToUpload] : []} onChange={handleFileChange}
            acceptType={['jpg', 'jpeg', 'png']} maxFileSize={2097152}>
            {({
                fileList,
                errors,
                isDragging,
                onFileUpload,
                onFileRemoveAll,
                onFileUpdate,
                onFileRemove,
                dragProps,
            }) => {
                return (
                    <>
                        <Box
                            id="btn-upload"
                            onClick={onFileUpload}
                            {...dragProps}
                            sx={styles.fileUploadContainer}
                        >
                            {errors && errors.maxNumber && (
                                <span>Number of selected files exceed maxNumber</span>
                            )}

                            <>
                                <Stack
                                    spacing={2}
                                    direction="column"
                                    justifyContent="space-between"
                                    sx={styles.fileUploadLabelContainer}
                                >
                                    <Typography noWrap
                                        sx={styles.fileUploadLabel}
                                    >
                                        {props?.label || "Choose file"}
                                    </Typography>

                                    {fileToUpload != null && (
                                        <Stack
                                            spacing={2}
                                            direction="row"
                                            justifyContent="space-between"
                                        >
                                            <Typography
                                                sx={styles.fileName}
                                            >
                                                {fileToUpload?.name}

                                            </Typography>

                                            <DeleteIcon
                                                onClick={(e) => handleRemoveFileClick(e)} />
                                        </Stack>)}
                                </Stack>
                                <img
                                    style={{
                                        filter: "brightness(70%)",
                                        borderRadius: "5px",
                                        height: "100%",
                                        width: "100%",
                                        objectFit: "cover"
                                    }}
                                    src={preview}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = require("../../../../src/assets/restaurant_placeholder.png");
                                    }}
                                />

                            </>

                        </Box>
                    </>
                );
            }}
        </FileUploading>
    )
}


const styles = {
    fileUploadContainer: {
        background: 'white',
        position: 'relative',
        overflow: 'hidden',
        color: '#AEAEB2', // theme.palette.primary.main,
        borderRadius: '8px',
        border: `3px dashed #AEAEB2`, // ${theme.palette.primary.main}`,
        height: '125px',
        textAlign: 'center',
        cursor: 'pointer',
        transition: 'filter ease-in-out 200ms',
        ':hover': {
            filter: 'brightness(0.85)'
        }
    },
    fileUploadLabelContainer: {
        position: 'absolute',
        zIndex: 1,
        color: 'white',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)'
    },
    fileUploadLabel: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    },
    fileName: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap'
    }
}