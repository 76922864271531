import { Box, Button, Stack, Theme, Typography, useTheme } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUserRestaurant } from "../../app/core/store/features/auth/authSlice";
import { RootState } from "../../app/core/store/store";
import useAxiosSecure from "../../hooks/useAxiosSecure";

export default function Overview() {

    const theme = useTheme();
    const styles = makeStyles(theme);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const axiosSecure = useAxiosSecure();
    const auth = useSelector((state: RootState) => state.auth);
    const t = useTranslation();

    useEffect(() => {
        let isOpen = auth?.restaurant?.isOpen;

        if (isOpen) {
            navigate("/overview/orders");
        }
    })


    async function handleOpenRestaurant() {
        let _restaurant = ({ ...auth?.user?.restaurant });

        let response = await axiosSecure.put(`/api/restaurant/open`,
            {
                id: _restaurant.id,
                isOpen: true
            }, true);

        if (response?.data && response?.data?.length > 0) {
            dispatch(updateUserRestaurant({
                restaurant: response?.data[0]
            }));
        }

        navigate("/overview/orders");
    }

    return (
        <Box sx={styles.container}>
            <Stack spacing={2} alignItems="center">
                {/* RESTAURANT */}
                {auth?.user?.restaurantId && <>
                    <Typography paragraph
                        sx={styles.title}
                    >
                        {t('pages.overview.labels.ordersView')}
                    </Typography>

                    <Button size="large" variant="contained"
                        onClick={handleOpenRestaurant}
                        sx={styles.openButton}
                    >
                        {t('pages.overview.actions.open')}
                    </Button>
                </>}

                {/* FESTIVAL */}
                {auth?.user?.festivalId && <>
                    <Typography paragraph
                        sx={styles.title}
                    >
                        {t('pages.overview.labels.festivalView')}
                    </Typography>


                </>}
            </Stack>
        </Box>
    );
}

const makeStyles = (theme: Theme) => {
    return {
        container: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh"
        },
        title: {
            fontSize: {
                lg: "xxx-large",
                md: "xx-large",
                sm: "x-large"
            },
            fontWeight: "bold",
            color: theme.palette.text.secondary
        },
        openButton: {
            fontSize: {
                lg: "large",
                md: "medium",
                sm: "small"
            },
            padding: {
                lg: "20px",
                md: "15px",
                sm: "10px"
            }
        }
    }
}